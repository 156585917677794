import './style.css'
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'

import Image from '../../components/Image'
import Inline from '../../components/Inline'

const COLORS = [
  'purple',
  'outline',
  'grey',
  'yellow',
  'red',
]

const TYPES = [
  'button',
  'submit',
]

class Button extends Component {

  render() {
    const {
      color,
      type,
      text,
      iconLeft,
      iconRight,
      children,
      isShadowed,
      isDisabled,
      isExpanded,
      onClick,
    } = this.props
    const buttonClass = classNames({
      'button': true,
      [`button-${color}`]: true,
      [`button-${color}-shadowed`]: isShadowed,
      'button-disabled': isDisabled,
      'button-expanded': isExpanded,
      'button-expanded-icon': iconLeft || iconRight,
    })

    return (
      <button
        className={buttonClass}
        type={type}
        disabled={isDisabled}
        onClick={onClick}
      >
        {iconLeft && (
          <Fragment>
            <Image
              src={iconLeft}
              alt="icon-left"
            />
            <Inline type="xs" />
          </Fragment>
        )}

        <span>
          {children || text}
        </span>

        {iconRight && (
          <Fragment>
            <Inline type="xs" />
            <Image
              src={iconRight}
              alt="icon-right"
            />
          </Fragment>
        )}
      </button>
    )
  }
}

Button.propTypes = {
  color: PropTypes.oneOf(COLORS),
  type: PropTypes.oneOf(TYPES),
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  isDisabled: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isShadowed: PropTypes.bool,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  color: COLORS[0],
  type: TYPES[0],
  text: undefined,
  children: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  isDisabled: false,
  isExpanded: false,
  isShadowed: false,
  onClick: undefined,
}

export default injectIntl(Button)
