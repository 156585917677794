import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ScreenClassProvider } from 'react-grid-system'

import CookiesConsent from '../CookiesConsent'

class Layout extends Component {

  render() {
    const { children } = this.props

    return (
      <div>
        <ScreenClassProvider>
          {children}
          <CookiesConsent />
        </ScreenClassProvider>
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  children: undefined,
}

export default Layout
