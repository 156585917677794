module.exports = {

  // Meta
  'meta.home.title': 'Wuha | Information at your fingertips',
  'meta.home.description': 'Wuha centralizes your information sources for a smooth and optimal search. Easily find your information among your Emails, documents stored on your computer or in the Cloud, your documentary collections...',
  'meta.features.title': 'Features | Wuha',
  'meta.features.description': 'Save up to 40% on your search time with Wuha’s features. Its Artificial Intelligence allows you to search in complete confidentiality and security within several sources of information at the same time.',
  'meta.ai.title': 'Artificial Intelligence | Wuha',
  'meta.ai.description': 'Wuha’s Artificial Intelligence gives you an extraordinary search experience. Don’t waste your time filing your documents, emails or naming them.',
  'meta.integrations.title': 'Integrations | Wuha',
  'meta.integrations.description': 'Access the complete catalogue of information and application sources. Connect to Wuha your storage sources, your conversation softwares, your document collections: Google Drive, DropBox, SharePoint, Outlook, Gmail, Editions Francis Lefebvre, Dalloz...',
  'meta.pricing.title': 'Pricing | Wuha',
  'meta.pricing.description': 'Your search assistant’s rates: transparent, flexible and fair. Try it for free for 30 days with no credit card required.',
  'meta.documentation.title': 'Documentation | Wuha',
  'meta.documentation.description': 'Data documentation at Wuha. All information on how our Data Science processes your data, our security measures and our commitment to your personal data.',
  'meta.dataManagement.title': 'Data Management | Wuha',
  'meta.dataManagement.description': 'Find out all the information about Data Science at Wuha. Dive into our Artificial Intelligence to understand the data it collects and how it works to return the most relevant results to you.',
  'meta.dataProtection.title': 'Data Protection | Wuha',
  'meta.dataProtection.description': 'Connect all your information sources to Wuha securely. Data security was thought at the genesis of Wuha. The structure of our system architecture is based on waterproof silos, secured and hosted in France and the data is encrypted.',
  'meta.privacyPolicy.title': 'Privacy Policy | Wuha',
  'meta.privacyPolicy.description': 'Connect your information sources in complete confidentiality. Wuha complies with the GDPR and anonymizes your data. You have the option to recover and/or delete your data. Discover how Wuha manages your personal data in a transparent way.',
  'meta.terms.title': 'Legal Notice | Wuha',
  'meta.terms.description': 'The legal notices of your Wuha search assistant: the Wuha General Terms and Conditions of Use, the SaaS General Terms and Conditions and the Privacy Policy.',
  'meta.about.title': 'About | Wuha',
  'meta.about.description': 'The entire Wuha team puts its heart into the work to offer you the best research experience. Get to know all the members who work with passion in Paris and Lyon.',
  'meta.blog.title': 'All about search, AI, SaaS and Wuha’s new features | Blog Wuha',
  'meta.blog.description': 'Discover the latest trends in search, AI, data security, SaaS and all the advices on innovations from the Wuha search assistant.',
  'meta.404.title': 'Page not found | Wuha',
  'meta.404.description': 'Page not found',

  // Message
  'meta.message.title': 'Wuha',
  'meta.message.description': '',
  'message.header.title': 'Wuha',
  'message.header.description': '',
  'message.body.1': 'If you\'d like to get in touch, please contact us at contact@wuha.io',

  // Cookies
  'cookies.action.accept': 'Accept',
  'cookies.description': 'By continuing to browse this site, you agree to the use of Cookies to collect statistics on visits. {knowMore}',
  'cookies.action.knowMore': 'Read more',

  // Global: Navigation
  'navigation.links.features': 'Features',
  'navigation.links.ai': 'Artificial Intelligence',
  'navigation.links.integrations': 'Integrations',
  'navigation.links.pricing': 'Pricing',
  'navigation.links.documentation': 'Documentation',
  'navigation.links.documentation.dataManagement': 'Data lifecycle at Wuha',
  'navigation.links.documentation.dataProtection': 'Security at Wuha',
  'navigation.links.documentation.privacyPolicy': 'Our commitment to protect your data',
  'navigation.links.about': 'About',
  'navigation.links.connection': 'Connection',
  'navigation.button.tryIt': 'Try for free',

  // Global: Breadcrumb
  'breadcrumb.wuha': 'Wuha',
  'breadcrumb.documentation': 'Documentation',
  'breadcrumb.features': 'Features',
  'breadcrumb.ai': 'Artificial Intelligence',
  'breadcrumb.integrations': 'Integrations',
  'breadcrumb.pricing': 'Pricing',
  'breadcrumb.about': 'About',
  'breadcrumb.blog': 'Blog',
  'breadcrumb.terms': 'Legal Notice',

  // Global: OptimizeWorkflow
  'optimizeWorkflow.title': 'Start optimizing your workflow',
  'optimizeWorkflow.description': 'Connect your information sources to search and share with ease',
  'optimizeWorkflow.action.getStarted': 'Get started',

  // Global: Security Mini
  'security.mini.title': 'We take security seriously.',
  'security.mini.action.readMore': 'Read more',
  'security.mini.action.uri': '/documentation',

  // Global: Footer
  'footer.header.tagline': 'Your colleague of the future',
  'footer.header.serviceStatus': 'Service status',
  'footer.header.copyright': '© 2018 Wuha',
  'footer.links.product.header': 'Product',
  'footer.links.product.items.features': 'Features',
  'footer.links.product.items.ai': 'Artificial Intelligence',
  'footer.links.product.items.integrations': 'Integrations',
  'footer.links.product.items.pricing': 'Pricing',
  'footer.links.product.items.dataManagement': 'Data Management',
  'footer.links.product.items.dataProtection': 'Data Protection',
  'footer.links.company.header': 'Team',
  'footer.links.company.items.about': 'About',
  'footer.links.company.items.blog': 'Blog',
  'footer.links.company.items.pp': 'Privacy Policy',
  'footer.links.company.items.tos': 'Legal Notice',
  'footer.links.social.header': 'Social',
  'footer.links.language.header': 'Language',
  'footer.links.language.items.english': 'English',
  'footer.links.language.items.french': 'Français',

  // 404: Content
  '404.content.title': '404',
  '404.content.description': 'This page doesn’t exist... so there’s not much to do here I guess 🐔.',
  '404.content.action.goBack': 'Go back home',

  // Home: Header
  'home.header.title': 'Information at your fingertips',
  'home.header.description': 'The search assistant who connects to all your sources of information to facilitate your work',
  'home.header.action.connect': 'Connect your sources',

  // Home: Customers
  'home.customers.description': 'And more than 500+ companies trust us',
  'home.customers.action.readMore': 'Read their story',

  // Home: Promise
  'home.promises.title': 'How Wuha optimizes your workflow',
  'home.promises.boost.title': 'Boost your productivity',
  'home.promises.boost.description': 'Looking for information? A file? A conversation? A contact? Wuha allows you to stop scattering and optimize on average 7 hours a week!',
  'home.promises.find.title': 'Easily find your information',
  'home.promises.find.description': 'Connect all your information sources to Wuha to search in only one place.',
  'home.promises.search.title': 'Search where and when you need it most',
  'home.promises.search.description': 'Wuha integrates seamlessly into your workflow by directly displaying search results where you work.',

  // Home: Install
  'home.install.title': 'How does it works?',
  'home.install.steps.1.title': 'Install the Wuha extension',
  'home.install.steps.1.description': 'Add the Wuha plugin to your Chrome, Firefox or Internet Explorer 11 browser',
  'home.install.steps.1.action': 'Install now',
  'home.install.steps.1.uri': '/artificial-intelligence',
  'home.install.steps.2.title': 'Connect your information sources',
  'home.install.steps.2.description': 'Securely connect your favorite software to Wuha',
  'home.install.steps.2.action': 'Read more',
  'home.install.steps.2.uri': '/integrations',
  'home.install.steps.3.title': 'Search',
  'home.install.steps.3.description': 'Search your information in complete confidentiality in Google and from anywhere in your browser with the shortcut CTRL + SPACE',
  'home.install.steps.3.action': 'Read more',
  'home.install.steps.3.uri': '/artificial-intelligence',
  'home.install.steps.4.title': 'Access all your information in a second',
  'home.install.steps.4.description': 'Our powerful Artificial Intelligence offers you the most suitable and relevant search results',
  'home.install.steps.4.action': 'Read more',
  'home.install.steps.4.uri': '/artificial-intelligence',

  // Home: Press
  'home.press.title': 'Recommended by the press',
  'home.press.usineDigitaletitle': 'Usine Digitale',
  'home.press.forbes.title': 'Forbes',
  'home.press.maddyness.title': 'Maddyness',
  'home.press.villageJustice.title': 'Village de la Justice',

  // Home: Feedbacks
  'home.feedbacks.title': 'Recommended by our customers',
  'home.feedbacks.feedback.vm.name': 'Véronique Maugeri,',
  'home.feedbacks.feedback.vm.position': 'Functional product manager @Éditions Francis Lefebvre',
  'home.feedbacks.feedback.vm.quote': 'The use of the Wuha solution opens up new perspectives on the relevance of search from the web browser and the first Google query.',
  'home.feedbacks.feedback.cp.name': 'Christophe Plissier,',
  'home.feedbacks.feedback.cp.position': 'Chief Financial Officer @GAC Group',
  'home.feedbacks.feedback.cp.quote': 'Thanks to Wuha, our consultants can now efficiently find all the technical documentation located in our servers and email exchanges.',
  'home.feedbacks.feedback.tb.name': 'Thomas Belhamri,',
  'home.feedbacks.feedback.tb.position': 'Co-founder @Boryl',
  'home.feedbacks.feedback.tb.quote': 'The Gmail extension is very useful to me! Typically, when I think I have received a message by email, with Wuha I quickly see that I actually received it on slack. That’s perfect!',

  // Home: Security
  'home.security.title': 'We take security seriously.',
  'home.security.privacy.title': 'Your privacy, our responsibility',
  'home.security.privacy.description': 'Wuha was designed from the beginning to protect your data on the Privacy by Design concept. Also the confidentiality of your data is respected and secure.',
  'home.security.security.title': 'A secure architecture, our priority',
  'home.security.security.description': 'The Wuha team puts security at the heart of the search engine. We regularly have our infrastructures and systems audited by cyber security experts to ensure the confidentiality and security of your information.',
  'home.security.action.readMore': 'Read more',
  'home.security.action.uri': '/documentation',

  // Features: Header
  'features.header.title': 'Simplified search',
  'features.header.description': 'Our Artificial Intelligence searches within the document content of all your information sources',
  'features.header.action.connect': 'Connect your sources',

  // Features: List
  'features.list.title': 'Key features',
  'features.list.ai.title': 'A robust Artificial Intelligence',
  'features.list.ai.description': 'Thanks to its robust technology based on Machine Learning and Artificial Intelligence, Wuha searches the contents of documents to return the most relevant results',
  'features.list.ai.action': 'Read more',
  'features.list.ai.uri': '/artificial-intelligence',
  'features.list.sources.title': 'Multiple sources',
  'features.list.sources.description': 'Connect as many sources of information to Wuha as you wish to search in only one place.',
  'features.list.sources.action': 'See integrations',
  'features.list.sources.uri': '/integrations',
  'features.list.displays.title': 'Simple and accessible',
  'features.list.displays.description': 'Wuha integrates directly into your workflow, where you need it most.',
  'features.list.displays.action': 'Discover',
  'features.list.displays.uri': '/artificial-intelligence#ai-displays',
  'features.list.privacy.title': 'Privacy',
  'features.list.privacy.description': 'In case of specific needs, you can disable the display of Wuha in Google at any time. For information, when you do a search on Google, the results displayed on the right by Wuha are never accessible and visible by Google.',
  'features.list.privacy.action': 'Read more',
  'features.list.privacy.uri': '/privacy-policy#19',
  'features.list.security.title': 'Secure system',
  'features.list.security.description': 'Your data is encrypted and hosted in France in SOC1, SOC2, ISO27001 certified data centers.',
  'features.list.security.action': 'Read more',
  'features.list.security.uri': '/data-protection',
  'features.list.ocr.title': 'OCR',
  'features.list.ocr.description': 'Search the content of all scanned documents, such as photos, PDFs.',
  'features.list.correction.title': 'Auto correction',
  'features.list.correction.description': 'If you make a spelling mistake, Wuha proposes a corrected query.',
  'features.list.completion.title': 'Auto completion',
  'features.list.completion.description': 'As you type, you will be offered personalized search suggestions.',

  // Features: Gains
  'features.gains.title': 'How Wuha improves your workflow',
  'features.gains.description': 'Because the search for information is at the heart of knowledge',
  'features.gains.1.description': '-40% of search time',
  'features.gains.1.alt': 'search',
  'features.gains.2.description': '30% of clicks saved',
  'features.gains.2.alt': 'clicks',
  'features.gains.3.description': '500+ companies',
  'features.gains.3.alt': 'users',
  'features.gains.4.description': '20+ Million encrypted documents',
  'features.gains.4.alt': 'encryption',

  // Artificial Intelligence: Header
  'ai.header.title': 'The power of our Artificial Intelligence',
  'ai.header.description': 'After more than 3 years of Research & Development, Wuha offers many features to offer you the best possible search experience.',
  'ai.header.action.connect': 'Connect your sources',

  // Artificial Intelligence: Strengths
  'ai.strengths.smartDisplay.id': 'strengths-smart-preview',
  'ai.strengths.smartDisplay.alt': 'smart preview',
  'ai.strengths.smartDisplay.title': 'Smart preview',
  'ai.strengths.smartDisplay.description': 'At a glance, identify the source of the information, the file format and the content of the results',
  'ai.strengths.filters.id': 'strengths-filters',
  'ai.strengths.filters.alt': 'filters',
  'ai.strengths.filters.title': 'Filters',
  'ai.strengths.filters.description': 'Select the source in which you want to target your searches.',
  'ai.strengths.datesAndFormats.alt': 'dates and formats',
  'ai.strengths.dates.id': 'strengths-dates',
  'ai.strengths.dates.title': 'Dates detection',
  'ai.strengths.dates.description': 'Wuha understands and interprets time information. So you can search by more or less approximate dates.',
  'ai.strengths.formats.id': 'strengths-formats',
  'ai.strengths.formats.title': 'Search by format',
  'ai.strengths.formats.description': 'Refine your searches by targeting the format in your requests.',
  'ai.strengths.similarResults.id': 'strengths-similar-results',
  'ai.strengths.similarResults.alt': 'similar results',
  'ai.strengths.similarResults.title': 'Similar results',
  'ai.strengths.similarResults.description': 'No more questions about the "right version" of a document! Wuha gives you the most recent and similar results associated with it.',
  'ai.strengths.nlp.id': 'strengths-language-interpretation',
  'ai.strengths.nlp.alt': 'language interpretation',
  'ai.strengths.nlp.title': 'Language interpretation',
  'ai.strengths.nlp.description': 'Our A.I. adapts to the vocabulary specific to your profession. Wuha manages to translate your acronyms, detect names, identify nominal groups...',
  'ai.strengths.nlp.action.readMore': 'Read more',
  'ai.strengths.nlp.action.uri': '/data-management#2',
  'ai.strengths.typology.id': 'document-typology',
  'ai.strengths.typology.alt': 'document typology',
  'ai.strengths.typology.title': 'The type of document',
  'ai.strengths.typology.description': 'No more renaming of your documents thanks to our algorithm that can interpret typologies such as resumes, contracts, invoices, quotes, etc.',

  // Artificial Intelligence: Displays
  'ai.displays.title': 'Wuha adapts to your needs',
  'ai.displays.description': 'We wanted to make the information accessible at all times',
  'ai.displays.bar.title': 'From your browser with CTRL + SPACE',
  'ai.displays.google.title': 'From Google',
  'ai.displays.wuha.title': 'From the Wuha online application',
  'ai.displays.slack.title': 'From Slack’s Wuha application',

  // Artificial Intelligence: DisplaysConfig
  'ai.displaysConfig.description': 'Choose where you want the results to appear precisely in your workflow',
  'ai.displaysConfig.action.seeIntegrations': 'See all other features',
  'ai.displaysConfig.action.uri': '/integrations',

  // Integrations: Header
  'integrations.header.title': 'Integrations',
  'integrations.header.description': 'Wuha connects to cloud-only solutions, and also to your Mac or PC computer.',
  'integrations.header.action.connect': 'Connect your sources',

  // Integrations: Sources
  'integrations.sources.storage.title': 'Storage sources',
  'integrations.sources.storage.description': 'Search securely in your documents, photos, videos and more with Wuha according to:',
  'integrations.sources.storage.point.1': 'their names and contents',
  'integrations.sources.storage.point.2': 'the date on which they were created or last modified',
  'integrations.sources.storage.point.3': 'their formats, such as PDF, DOC, PowerPoint etc.',
  'integrations.sources.storage.point.4': 'their authors and contributors',
  'integrations.sources.messaging.title': 'Messaging softwares',
  'integrations.sources.messaging.description': 'You don’t know where you discussed it? Securely search all your emails and attachments with Wuha by:',
  'integrations.sources.messaging.point.1': 'their subject, labels or content',
  'integrations.sources.messaging.point.2': 'the format of attachments, such as PDF, DOC, PowerPoint etc',
  'integrations.sources.messaging.point.3': 'the date on which the e-mail was sent or received',
  'integrations.sources.messaging.point.4': 'the sender, recipients or other persons in the e-mail exchange chain',
  'integrations.sources.legal.title': 'Legal softwares',
  'integrations.sources.legal.description': 'Simply access your knowledge through multidisciplinary documentary collections that provide you with a complete overview of the law. Secure your decisions by searching directly in:',
  'integrations.sources.legal.point.1': 'the Materials that concern you',
  'integrations.sources.legal.point.2': 'Mementos & legal documentary collections commented and analysed',
  'integrations.sources.legal.point.3': 'Reviews & all the latest legal, tax, social, accounting, real estate, heritage, family and association news',
  'integrations.sources.legal.point.4': 'Case studies',
  'integrations.sources.legal.point.5': 'Models',
  'integrations.sources.box.title': 'Box',
  'integrations.sources.dropbox.title': 'DropBox',
  'integrations.sources.gdrive.title': 'Google Drive',
  'integrations.sources.msonedrive.title': 'Microsoft OneDrive',
  'integrations.sources.mssharepoint.title': 'Microsoft SharePoint',
  'integrations.sources.fs_macos.title': 'MacOS',
  'integrations.sources.fs_mswindows.title': 'Microsoft Windows',
  'integrations.sources.slack.title': 'Slack',
  'integrations.sources.msoutlook.title': 'Microsoft Outlook',
  'integrations.sources.gmail.title': 'Gmail',
  'integrations.sources.efl.title': 'Editions Francis Lefèbvre',
  'integrations.sources.dalloz.title': 'Dalloz Avocats',

  // Integrations: Incoming
  'integrations.incoming.title': 'Upcoming launches',
  'integrations.incoming.description': 'To your great pleasure, our team is working diligently on the development of the next applications',
  'integrations.incoming.gcalendar.title': 'Google Calendar',
  'integrations.incoming.el.title': 'Éditions Législatives',
  'integrations.incoming.asana.title': 'Asana',
  'integrations.incoming.quickbooks.title': 'Quickbooks',
  'integrations.incoming.salesforce.title': 'Salesforce',
  'integrations.incoming.evernote.title': 'Evernote',
  'integrations.incoming.trello.title': 'Trello',

  // Documentation: Header
  'documentation.header.title': 'Documentation',
  'documentation.header.description': 'Wuha was designed from the beginning to protect your data on the Privacy by Design concept',

  // Documentation: Summary
  'documentation.links.dataManagement': 'Data lifecycle at Wuha',
  'documentation.links.dataProtection': 'Security at Wuha',
  'documentation.links.privacyPolicy': 'Our commitment to protect your data',
  'documentation.anchors.dataManagement.1': 'What does Wuha recover?',
  'documentation.anchors.dataManagement.2': 'Your data for the best results',
  'documentation.anchors.dataProtection.1': 'Our key actions to protect your data',
  'documentation.anchors.dataProtection.2': 'Where is your data stored?',
  'documentation.anchors.dataProtection.3': 'How your data is protected by us?',
  'documentation.anchors.dataProtection.4': 'Zoom on our multi-layer structure',
  'documentation.anchors.dataProtection.5': 'Our tested and proven infrastructure',
  'documentation.anchors.privacyPolicy.1': 'Our responsibility',
  'documentation.anchors.privacyPolicy.2': 'Your responsibility',
  'documentation.anchors.privacyPolicy.3': 'About this Privacy Policy',
  'documentation.anchors.privacyPolicy.4': 'Definitions',
  'documentation.anchors.privacyPolicy.5': 'When does this Privacy Policy not apply?',
  'documentation.anchors.privacyPolicy.6': 'How can I contact you?',
  'documentation.anchors.privacyPolicy.7': 'What information do we collect about you?',
  'documentation.anchors.privacyPolicy.8': 'Why certain information is mandatory to provide?',
  'documentation.anchors.privacyPolicy.9': 'Why are you using my information?',
  'documentation.anchors.privacyPolicy.10': 'How long do you keep my information?',
  'documentation.anchors.privacyPolicy.11': 'Do you profile my information?',
  'documentation.anchors.privacyPolicy.12': 'To whom are my information communicated?',
  'documentation.anchors.privacyPolicy.13': 'How do we secure your information?',
  'documentation.anchors.privacyPolicy.14': 'Where is my information stored?',
  'documentation.anchors.privacyPolicy.15': 'Do you use cookies?',
  'documentation.anchors.privacyPolicy.16': 'How can I prevent the use of cookies?',
  'documentation.anchors.privacyPolicy.17': 'What are my rights?',
  'documentation.anchors.privacyPolicy.18': 'How to exercise my rights?',

  // DocumentationDetail : Navigation
  'documentation.dataManagement': 'Data lifecycle at Wuha',
  'documentation.dataProtection': 'Security at Wuha',
  'documentation.privacyPolicy': 'Our commitment to protect your data',

  // Pricing: Header
  'pricing.header.title': 'A price that adapts to your needs',
  'pricing.header.description.1': 'Transparent. Flexible. Fair trade.',
  'pricing.header.description.2': 'Try it for free for 30 days. Credit card not required.',

  // Pricing: Offers
  'pricing.offers.toggle.yearly.title': 'Yearly',
  'pricing.offers.toggle.monthly.title': 'Monthly',
  'pricing.offers.card.free.title': 'Free',
  'pricing.offers.card.free.price': '0€',
  'pricing.offers.card.free.button': 'Sign up for free',
  'pricing.offers.card.standard.title': 'Standard',
  'pricing.offers.card.standard.price': '{price}{currency} HT',
  'pricing.offers.card.standard.caption': 'by user, per month',
  'pricing.offers.card.standard.button': 'Test for 30 days freely',
  'pricing.offers.card.business.title': 'Business',
  'pricing.offers.card.business.price': 'Contact us',
  'pricing.offers.card.business.button': 'Contact us',
  'pricing.offers.card.features.allMain': 'All the main features',
  'pricing.offers.card.features.see': 'See',
  'pricing.offers.card.features.free.indexing': 'Data indexing: 10GB',
  'pricing.offers.card.features.free.history': 'Search history: 30 days',
  'pricing.offers.card.features.standard.indexing': 'Data indexing: Unlimited',
  'pricing.offers.card.features.standard.history': 'Search history: Unlimited',
  'pricing.offers.card.features.standard.fs': 'Indexation Finder Mac and Windows Explorer',
  'pricing.offers.card.features.business.customApps': 'Custom applications',
  'pricing.offers.card.features.business.dedicatedSupport': 'Dedicated Support',
  'pricing.offers.card.features.business.personalizedSupport': 'Personalized support',
  'pricing.offers.card.features.business.analytics': 'Custom data reporting and analysis',

  // Pricing: Features
  'pricing.features.title': 'Main features included in each offer',
  'pricing.features.1': 'Connection to all applications',
  'pricing.features.2': 'Intelligent search algorithm',
  'pricing.features.3': 'Multiple accounts per application',
  'pricing.features.4': 'Limited data indexing (variable)',
  'pricing.features.5': 'Preview of the enriched content',
  'pricing.features.6': 'Search history (variable)',
  'pricing.features.7': 'Data security and encryption',
  'pricing.features.8': 'Multiple display modes',

  // Pricing: Keywords
  'pricing.faq.questions.plan.free': 'Free',
  'pricing.faq.questions.plan.standard': 'Standard',
  'pricing.faq.questions.keyword.here': 'here',
  'pricing.faq.questions.keyword.knowMore': 'Read more',
  'pricing.faq.questions.keyword.privacy': 'Privacy',
  'pricing.faq.questions.keyword.theExtension': 'the extension',
  'pricing.faq.questions.keyword.google': 'Google',
  'pricing.faq.questions.keyword.connectedApps': 'connected sources',

  // Pricing: QA
  'pricing.faq.title': 'Frequently asked questions',

  // Pricing: QA -> Price
  'pricing.faq.price.title': 'Pricing',
  'pricing.faq.price.1.question': 'In the Free plan, the 30-day search history plays on documents created or modified 30 days ago?',
  'pricing.faq.price.1.answer': 'In the Free plan, you only have access to search results on e-mails, documents and information modified in the last 30 days.',
  'pricing.faq.price.2.question': 'Are bank details required during the test period?',
  'pricing.faq.price.2.answer': 'No. For ethical reasons, we want the user to be free to test and use Wuha for free without having to enter his bank details first.',
  'pricing.faq.price.3.question': 'Is the subscription automatically renewed?',
  'pricing.faq.price.3.answer': 'Yes, whether for the Monthly or Annual subscription of the Standard Plan, the monthly payment is automatically renewed.',
  'pricing.faq.price.4.question': 'Can I unsubscribe at any time?',
  'pricing.faq.price.4.answer': 'Yes of course. You can unsubscribe and re-subscribe in one click in your account. Once unsubscribed, you have access to the features of the Standard plan until the end of the current month.',
  'pricing.faq.price.5.question': 'Can I use Wuha for free?',
  'pricing.faq.price.5.answer': 'Of course! You can use it daily for free by subscribing to the {freePlan} plan.',
  'pricing.faq.price.6.question': 'What happens after the free month of the Standard plan',
  'pricing.faq.price.6.answer': 'After a month of free trial, you will have the choice between:',
  'pricing.faq.price.6.answer.1': 'Stay in the {standardPlan} plan for 7€ HT per month',
  'pricing.faq.price.6.answer.2': 'Choose the plan {freePlan}',

  // Pricing: QA -> Data
  'pricing.faq.data.title': 'Data',
  'pricing.faq.data.1.question': 'How is my data secured?',
  'pricing.faq.data.1.answer': 'Our site has been designed on the principle of Privacy by Design. Our system architecture is also organized in waterproof, autonomous and secure silos, which prevents, in the event of an attack, the exploitation of data. Our last audit by Thalès CyberSecurity received a score of 4.98/5.',
  'pricing.faq.data.2.question': 'How is my personal data used?',
  'pricing.faq.data.2.answer': 'In all transparency, find out {link} how we treat your personal data.',
  'pricing.faq.data.3.question': 'How does Wuha work to provide me with relevant results?',
  'pricing.faq.data.3.answer': 'In order to provide you with the best search result, Wuha needs access to the content and metadata of your documents and emails. Once this information has been indexed, the algorithm developed by our teams analyses the stored data and produces the most appropriate results based on pre-established criteria.',
  'pricing.faq.data.4.question': 'Does Wuha keep the restrictions on access to folders / files?',
  'pricing.faq.data.4.answer': 'That’s right. For example, if you only have access to certain folders in Google Drive, then Wuha will index only those folders. All confidentiality within the teams is then maintained. This is valid for all applications that support this type of restriction (SharePoint, My Computer, Dropbox, etc...)',
  'pricing.faq.data.5.question': 'Can I delete my data from Wuha?',
  'pricing.faq.data.5.answer': 'Of course, you can delete your data directly from your account > {link}',
  'pricing.faq.data.6.question': 'Does Google have access to my data?',
  'pricing.faq.data.6.answer': 'No. When you do a search on Google, the results displayed on the right by Wuha are not accessible and visible by Google.',

  // Pricing: QA -> Product
  'pricing.faq.product.title': 'Product',
  'pricing.faq.product.1.question': 'Is Wuha a software or an online application?',
  'pricing.faq.product.1.answer': 'Wuha is a solution available online. It has several modes of use:',
  'pricing.faq.product.1.answer.1': 'on the Web App: {link}',
  'pricing.faq.product.1.answer.2': 'after connecting {link}',
  'pricing.faq.product.1.answer.2.1': 'to the right of the search engine results {link}',
  'pricing.faq.product.1.answer.2.2': 'anywhere from your Chrome, Firefox or Internet Explorer 11 browser with the shortcut CTRL + SPACE',
  'pricing.faq.product.1.answer.3': 'Only the "My Computer" application requires the download of software to synchronize your documents with Wuha',
  'pricing.faq.product.2.question': 'Does Wuha work on mobile phones?',
  'pricing.faq.product.2.answer': 'Yes, Wuha is available on mobile on {link}',
  'pricing.faq.product.3.question': 'What is the list of applications I can connect?',
  'pricing.faq.product.3.answer': 'An application is an online solution that you use such as Google Drive, Gmail, Dropbox etc.',
  'pricing.faq.product.3.answer.1': 'The complete list is available {link}',
  'pricing.faq.product.4.question': 'Does Wuha present the different versions of a document?',
  'pricing.faq.product.4.answer': 'Yes, thanks to its algorithm, Wuha detects similar documents and gives them back to you clearly in the search results.',

  // About: Header
  'about.header.title': 'About us',
  'about.header.description.1': 'In our respective professional experiences, we were frustrated to waste so much time searching for information through all these sources of information: CRM, file servers, Intranet, emails and many others. We knew that this had no added value.',
  'about.header.description.2': 'That is why we created Wuha: we want every employee to have easy access to information where they need it, when they need it, so they can focus on what is important.',
  'about.header.signature': 'Sacha | Antonin | Steven',

  // About: Values
  'about.values.title': 'The values that guide us',
  'about.values.transparency.title': 'Transparency',
  'about.values.transparency.description': 'We believe that being totally transparent with our teams and with our customers allows us to solve arguments faster and to take full responsibility.',
  'about.values.teamspirit.title': 'Team spirit',
  'about.values.teamspirit.description': 'We are convinced that it is the strength of our team that will allow us to reinvent ourselves all the time. Team spirit also means relying on each other and sticking together in intense moments.',
  'about.values.expectations.title': 'High expectation',
  'about.values.expectations.description': 'The involvement and commitment of each and every one allows us to take on each challenge in a constructive dynamic to always meet the expectations of our users.',

  // About: Team
  'about.team.title': 'Let’s meet!',
  'about.team.members.sacha.title': 'CEO & Co-founder',
  'about.team.members.sacha.description': 'I would like Wuha to get me a driving licence',
  'about.team.members.antonin.title': 'CMO & Co-founder',
  'about.team.members.antonin.description': 'I would like Wuha to find me a parking space in town',
  'about.team.members.steven.title': 'CTO & Co-founder',
  'about.team.members.steven.description': 'I would like Wuha to find me my stolen bike',
  'about.team.members.juliette.title': 'Growth Marketer',
  'about.team.members.juliette.description': 'I would like Wuha to find me light, organic and made in Lyon M&M’s',
  'about.team.members.charly.title': 'Customer Success Manager',
  'about.team.members.charly.description': 'I would like Wuha to find me an eighth day in the week',
  'about.team.members.alexis.title': 'Sales Engineer',
  'about.team.members.alexis.description': 'I would like Wuha to find me the numbers of the next national lottery',
  'about.team.members.timothee.title': 'Lead Data Scientist',
  'about.team.members.timothee.description': 'I would like Wuha to find me some OL victories',
  'about.team.members.yohann.title': 'Data Scientist',
  'about.team.members.yohann.description': 'I would like Wuha to find me a picture of my former 5th grade music teacher',
  'about.team.members.raphael.title': 'Data Scientist',
  'about.team.members.raphael.description': 'I would like Wuha to find me where the stock of Charly candy is hidden',
  'about.team.members.kassim.title': 'Data Scientist',
  'about.team.members.kassim.description': 'I would like Wuha to find me the best tacos in Lyon',
  'about.team.members.aymeric.title': 'Software Engineer',
  'about.team.members.aymeric.description': 'I would like Wuha to find me some new jokes',
  'about.team.members.maxime.title': 'Software Engineer',
  'about.team.members.maxime.description': 'I would like Wuha to find me a list of the best Kickstarter and Indiegogo projects',
  'about.team.members.jerome.title': 'Systems Engineer',
  'about.team.members.jerome.description': 'I would like Wuha to find me...',

  // About: Location
  'about.location.title': 'Where the magic happens',
  'about.location.lyon.title': 'In Lyon',
  'about.location.lyon.address': '40 rue Laure Diebold - 69009 Lyon, France',
  'about.location.paris.title': 'In Paris',
  'about.location.paris.address': 'Station F : 5 Parvis Alan Turing, Paris 13ème',
  'about.location.contact': 'You can reach us at any time at {email} or by {chat}',

  // Blog: Header
  'blog.header.title': 'Blog',
  'blog.header.description': 'Wuha’s latest news',

  // Blog: Post
  'blog.posts.post.authorDate': '{name} posted on {date}',
  'blog.posts.post.action.readMore': 'Read more',

  // Terms: Header
  'terms.header.title': 'Legal notice',
  'terms.header.description': 'All legal information on hosting and Wuha’s general terms of use',

  // Terms: Content
  'terms.content.here': 'here',
  'terms.content.company.title': 'Wuha with a capital of €8,740',
  'terms.content.company.rcs': 'N° RCS: 810 665 273',
  'terms.content.company.tva': 'N° TVA: FR 94 810 665 273',
  'terms.content.company.editor': 'Editor : Sacha Gallo Parouty',
  'terms.content.hosting.title': 'OVH',
  'terms.content.hosting.street': '2 rue Kellermann',
  'terms.content.hosting.city': '59100 Roubaix',
  'terms.content.hosting.country': 'France',
  'terms.content.cnil.title': 'CNIL Declaration',
  'terms.content.cnil.description': 'CNIL Declaration number: 1920582 v 0',
  'terms.content.tou.title': 'Terms of Use',
  'terms.content.tou.description': 'You can download the terms of use {link}.',
  'terms.content.saas.title': 'SaaS Terms of Use',
  'terms.content.saas.description': 'You can download the SaaS terms of use {link}.',
  'terms.content.pp.title': 'Privacy Policy',
  'terms.content.pp.description': 'You can access our privacy policy {link}.',

  // Enterprise
  'enterprise.meta.title': 'Wuha for the Enterprise',
  'enterprise.meta.description': 'Wuha helps businesses build and deploy powerful in-house search applications. With Wuha, companies create engaging experiences for their users whilst respecting the privacy of their information. Your data stays within your organisation.',
  'enterprise.meta.knowledgeManagers.title': 'Knowledge Managers | Wuha',
  'enterprise.meta.knowledgeManagers.description': 'Wuha helps Knowledge Managers provide the best search tools for their employees, improving productivity and maximizing the use of internal information. Wuha was built to give companies the power of enterprise search whilst keeping all data within the organisation.',
  'enterprise.meta.contentCreators.title': 'Content Creators | Wuha',
  'enterprise.meta.contentCreators.description': 'Wuha helps Content Creators empower their data with engaging search applications. With Wuha, companies are able to offer all the features of a powerful search engine without the need for technical search expertise. Wuha helps you maximize the potential of your content without it leaving your organisation.',
  'enterprise.meta.searchExpertise.title': 'Search Expertise | Wuha',
  'enterprise.meta.searchExpertise.description': 'Use Wuha\'s years of experience to build your own intelligent search engine. Our expertise in information retrieval and machine learning is at your fingertips.',

  'breadcrumb.enterprise': 'Enterprise',
  'breadcrumb.enterprise.knowledgeManagers': 'Knowledge Managers',
  'breadcrumb.enterprise.contentCreators': 'Content Creators',
  'breadcrumb.enterprise.searchExpertise': 'Search Expertise',

  'enterprise.navigation.button.contactUs': 'Contact Us',

  'enterprise.footer.header.tagline': 'Enterprise search solution',
  'enterprise.footer.links.solutions.header': 'Solutions',
  'enterprise.footer.links.solutions.items.forContentCreators': 'Content Creators',
  'enterprise.footer.links.solutions.items.forKnowledgeManagers': 'Knowledge Managers',
  'enterprise.footer.links.solutions.items.searchExpertise': 'Search Expertise',
  'enterprise.footer.links.solutions.items.apiDocumentation': 'API Documentation',

  'enterpriseNavigation.links.solutions': 'Solutions',
  'enterpriseNavigation.links.contentCreators': 'Content Creators',
  'enterpriseNavigation.links.knowledgeManagers': 'Knowledge Managers',
  'enterpriseNavigation.links.ourExpertise': 'Search Expertise',
  'enterpriseNavigation.links.apiDocumentation': 'API Documentation',

  'enterprise.header.id': 'search',
  'enterprise.header.title': 'Empower your organisation with in-house search',
  'enterprise.header.description': 'Wuha helps businesses build and deploy powerful in-house search applications. With Wuha, companies create engaging experiences for their users whilst respecting the privacy of their information. Your data stays within your organisation.',
  'enterprise.header.action.connect': 'Contact Us',

  'enterprise.contentCreators.header.id': 'engage',
  'enterprise.contentCreators.header.title': 'Create an engaging user experience with search',
  'enterprise.contentCreators.header.description': 'Wuha helps Content Creators empower their data with engaging search applications. With Wuha, companies are able to offer all the features of a powerful search engine without the need for technical search expertise. Wuha helps you maximize the potential of your content without it leaving your organisation.',
  'enterprise.contentCreators.header.action.connect': 'Contact Us',

  'enterprise.contentCreators.arguments.costTraining.id': 'search_expert',
  'enterprise.contentCreators.arguments.costTraining.alt': 'Your own search expert',
  'enterprise.contentCreators.arguments.costTraining.title': 'Your own search expert',
  'enterprise.contentCreators.arguments.costTraining.description': 'Wuha collects years of experience of working on search and presents it in a format easy to use and understandable.',

  'enterprise.contentCreators.arguments.complicated.id': 'complexity',
  'enterprise.contentCreators.arguments.complicated.alt': 'Easily build complex search engines',
  'enterprise.contentCreators.arguments.complicated.title': 'Easily build complex search engines',
  'enterprise.contentCreators.arguments.complicated.description': 'Wuha takes complicated subjects such as NLP and NER and makes them accessible to businesses.',

  'enterprise.contentCreators.arguments.control.id': 'build',
  'enterprise.contentCreators.arguments.control.alt': 'Build your own search engine',
  'enterprise.contentCreators.arguments.control.title': 'Build your own search engine',
  'enterprise.contentCreators.arguments.control.description': 'Wuha lets you customize your own search engine with relevancy rules that you decide. Our goal is to have perfect defaults, but you can modify weights, treatments and processing rules to build your own search engine.',

  'enterprise.contentCreators.arguments.dataSecurity.id': 'security',
  'enterprise.contentCreators.arguments.dataSecurity.alt': 'Your data stays with you',
  'enterprise.contentCreators.arguments.dataSecurity.title': 'Your data stays with you',
  'enterprise.contentCreators.arguments.dataSecurity.description': 'Wuha is deployed within your organisation. No data leaves your servers, allowing you to index and search for sensitive information.',

  'enterprise.knowledgeManagers.header.id': 'productivity',
  'enterprise.knowledgeManagers.header.title': 'Boost your colleagues\' productivity with search technology',
  'enterprise.knowledgeManagers.header.description': 'Wuha helps Knowledge Managers provide the best search tools for their employees, improving productivity and maximizing the use of internal information. Wuha was built to give companies the power of enterprise search whilst keeping all data within the organisation.',
  'enterprise.knowledgeManagers.header.action.connect': 'Contact Us',

  'enterprise.knowledgeManagers.arguments.costTraining.id': 'team',
  'enterprise.knowledgeManagers.arguments.costTraining.alt': 'Your own search team',
  'enterprise.knowledgeManagers.arguments.costTraining.title': 'Your own search team',
  'enterprise.knowledgeManagers.arguments.costTraining.description': 'Wuha collects years of experience of working on search and big data. We present these features in an easy-to-use and understandable format.',

  'enterprise.knowledgeManagers.arguments.control.id': 'customise',
  'enterprise.knowledgeManagers.arguments.control.alt': 'Customisable and controlled performance',
  'enterprise.knowledgeManagers.arguments.control.title': 'Customisable and controlled performance',
  'enterprise.knowledgeManagers.arguments.control.description': 'Wuha lets you customize your own search engine with relevancy rules that you decide. Our goal is to have perfect defaults, but you can modify weights, treatments and processing rules to build your own search engine.',

  'enterprise.knowledgeManagers.arguments.complicated.id': 'complexity',
  'enterprise.knowledgeManagers.arguments.complicated.alt': 'Easily build complex search engines',
  'enterprise.knowledgeManagers.arguments.complicated.title': 'Easily build complex search engines',
  'enterprise.knowledgeManagers.arguments.complicated.description': 'Wuha takes complicated subjects such as NLP and NER and makes them accessible to businesses.',

  'enterprise.knowledgeManagers.arguments.dataSecurity.id': 'security',
  'enterprise.knowledgeManagers.arguments.dataSecurity.alt': 'Your data stays with you',
  'enterprise.knowledgeManagers.arguments.dataSecurity.title': 'Your data stays with you',
  'enterprise.knowledgeManagers.arguments.dataSecurity.description': 'Wuha is deployed within your organisation by your information security team. No data leaves your servers, allowing you to index and search for sensitive information.',

  'enterprise.searchExpertise.header.id': 'complexity',
  'enterprise.searchExpertise.header.title': 'Master the complexity of search',
  'enterprise.searchExpertise.header.description': 'Use Wuha\'s years of experience to build your own intelligent search engine. Our expertise in information retrieval and machine learning is at your fingertips.',
  'enterprise.searchExpertise.header.action.connect': 'Contact Us',

  'enterprise.searchExpertise.lowLevelExpertise.id': 'integrated',
  'enterprise.searchExpertise.lowLevelExpertise.alt': 'Integrated with ElasticSearch',
  'enterprise.searchExpertise.lowLevelExpertise.title': 'Integrated with ElasticSearch',
  'enterprise.searchExpertise.lowLevelExpertise.description': 'Learning ElasticSearch is a time-consuming task. We\'ve spent years working with the technology and have integrated the best practices and features into Wuha.',

  'enterprise.searchExpertise.scalable.id': 'scalable',
  'enterprise.searchExpertise.scalable.alt': 'Scalable and fast',
  'enterprise.searchExpertise.scalable.title': 'Scalable and fast',
  'enterprise.searchExpertise.scalable.description': 'Search needs to be instant. Wuha and ElasticSearch scale with your search needs, both are technologies proven to work with millions of documents and thousands of daily requests.',

  'enterprise.searchExpertise.makeItEasy.id': 'features',
  'enterprise.searchExpertise.makeItEasy.alt': 'Complex features made simple',
  'enterprise.searchExpertise.makeItEasy.title': 'Complex features made simple',
  'enterprise.searchExpertise.makeItEasy.description': 'Include features such as NLP and NER into your search engine using a simple interface. Wuha reduces the complexity of these features helping you build powerful search applications quickly.',

  'enterprise.searchExpertise.beAutonomous.id': 'experts',
  'enterprise.searchExpertise.beAutonomous.alt': 'Made for domain experts',
  'enterprise.searchExpertise.beAutonomous.title': 'Made for domain experts',
  'enterprise.searchExpertise.beAutonomous.description': 'Wuha is built for people who understand their domain and need powerful search. We accompany business leaders in bringing the power of search to their industry.',
}
