import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Image from '../../../../components/Image'

const socialItemClass = classNames({
  'footer-social-item': true,
})

class SocialItem extends Component {

  render() {
    const {
      path,
      url,
      alt,
    } = this.props

    return (
      <a
        className={socialItemClass}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          src={path}
          alt={alt}
        />
      </a>
    )
  }
}

SocialItem.propTypes = {
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}

SocialItem.defaultProps = {
  path: undefined,
  url: undefined,
  alt: undefined,
}

export default SocialItem
