import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import I18nLink from '../../../../components/I18nLink'

const linkItemClass = classNames({
  'footer-link-item': true,
})

class LinkItem extends Component {

  render() {
    const {
      text,
      uri,
    } = this.props

    return (
      <I18nLink to={uri}>
        <p className={linkItemClass}>
          {text}
        </p>
      </I18nLink>
    )
  }
}

LinkItem.propTypes = {
  text: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
}

LinkItem.defaultProps = {
  text: undefined,
  uri: undefined,
}

export default LinkItem
