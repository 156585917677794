import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import injectContext from '../../../../components/injectContext'

const languageItemClass = classNames({
  'footer-language-item': true,
})

class LanguageItem extends Component {

  // Handlers
  handleClick = (event) => {
    const {
      lang,
    } = this.props

    localStorage.setItem('lang', lang)
  }


  // Render
  render() {
    const {
      text,
      lang,
      slug,
    } = this.props

    const href = slug && slug.startsWith('/blog/') ? `/${lang}/blog` : `/${lang}${slug}`

    return (
      <a href={href}>
        <p
          className={languageItemClass}
          onClick={this.handleClick}
        >
          {text}
        </p>
      </a>
    )
  }
}

LanguageItem.propTypes = {
  text: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
}

LanguageItem.defaultProps = {
  text: undefined,
  lang: undefined,
}

export default injectContext(LanguageItem)
