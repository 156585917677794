import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const imageClass = classNames({
  'image': true,
})

class Image extends Component {

  render() {
    const {
      src,
      alt,
      height,
      width,
      style,
    } = this.props

    return (
      <img
        className={imageClass}
        src={src}
        alt={alt}
        height={height}
        width={width}
        style={style}
      />
    )
  }
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  height: PropTypes.any,
  width: PropTypes.any,
  style: PropTypes.any,
}

export default Image
