import React, { Component } from 'react'

import { PageContextConsumer } from '../PageContext'

const injectContext = (WrappedComponent) => {

  class HOC extends Component {

    render() {
      return (
        <PageContextConsumer>
          {context => {
            return (
              <WrappedComponent
                {...this.props}
                pagecontext={context}
                {...context}
              />
            )
          }}
        </PageContextConsumer>
      )
    }
  }

  return HOC
}

export default injectContext
