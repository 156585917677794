import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import I18nLink from '../../../../components/I18nLink'

const containerClass = classNames({
  'navigation-sub-item-container': true,
})

class SubItem extends Component {

  render() {
    const {
      text,
      uri,
      url,
    } = this.props

    return (
      <div className={containerClass}>
        {
          uri ?
            (
              <I18nLink to={uri} alt={text}>
                {text}
              </I18nLink>
            ) :
            (
              <a href={url}>
                {text}
              </a>
            )
        }
      </div>
    )
  }
}

SubItem.protoTypes = {
  text: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

SubItem.defaultProps = {
  text: undefined,
  uri: undefined,
  url: undefined,
}

export default SubItem
