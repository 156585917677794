import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'


const TYPES = [
  'thin',
  'xxs',
  'xs',
  's',
  'm',
  'l',
  'xl',
  'xxl',
  'giant',
]

class Inline extends Component {

  render() {
    const {
      type,
    } = this.props
    const wuhaSpaceClass = classNames({
      'inline': true,
      [`inline-${type}`]: true,
    })

    return (
      <span className={wuhaSpaceClass} />
    )
  }
}

Inline.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
}

Inline.defaultProps = {
  type: TYPES[4],
}

export default Inline
