import React, { Component } from "react"
import Helmet from 'react-helmet'

const GA_TRACKING_ID = process.env.GATSBY_GA_TRACKING_ID

class AnalyticsGoogle extends Component {

  render() {
    if (typeof window === 'undefined' || !GA_TRACKING_ID || window.ga) {
      return null
    }

    return (
      <Helmet>
        <script async src='https://www.google-analytics.com/analytics.js'></script>
        <script type="text/javascript">
          {(function () {
            var ga = window.ga = window.ga || function () {
              (ga.q = ga.q || []).push(arguments)
            }
            ga.l = +new Date()
            ga('create', GA_TRACKING_ID, 'auto')
            // Set to false because otherwise we cannot exclude our own IP
            ga('set', 'anonymizeIp', false)

            // If you're looking to where we track page views, check gatsby-browser.js
            // ga('send', 'pageview')
          })()}
        </script>
      </Helmet>
    )
  }
}

export default AnalyticsGoogle
