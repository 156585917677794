import './style.css'
import React, { Component, Fragment } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import Cookies from 'js-cookie'

import AnalyticsGoogle from '../AnalyticsGoogle'
import AnalyticsSegment from '../AnalyticsSegment'
import Button from '../Button'
import I18nLink from '../I18nLink'
import Inline from '../Inline'

const COOKIE_NAME = 'cookies-consent'
const COOKIE_EXPIRES = 60 * 60 * 24 * 365

const containerClass = classNames({
  'cookies-container': true,
})

class CookiesConsent extends Component {

  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    const cookiesConsent = Cookies.get(COOKIE_NAME)
    const areCookiesAccepted = cookiesConsent && cookiesConsent === 'true'

    this.setState({
      areCookiesAccepted,
    })
  }

  // Handlers
  handleAccept = () => {
    Cookies.set(COOKIE_NAME, true, {
      expires: COOKIE_EXPIRES,
    })

    this.setState({
      areCookiesAccepted: true,
    })
  }


  // Render
  render() {
    const { areCookiesAccepted } = this.state

    return areCookiesAccepted ?
      this.renderAnalytics() :
      this.renderConsent()
  }

  renderConsent = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <div className={containerClass}>
        <div>
          <p>
            <FormattedMessage
              id="cookies.description"
              values={{
                knowMore: (
                  <I18nLink to="/privacy-policy#15">
                    {formatMessage({ id: 'cookies.action.knowMore' })}
                  </I18nLink>
                ),
              }}
            />
          </p>
        </div>
        <Inline type="m" />
        <Button
          text={formatMessage({ id: 'cookies.action.accept' })}
          color="red"
          style={{
            marginLeft: 'auto',
          }}
          isShadowed
          onClick={this.handleAccept}
        />
      </div>
    )
  }

  renderAnalytics = () => {
    return (
      <Fragment>
        <AnalyticsGoogle />
        <AnalyticsSegment />
      </Fragment>
    )
  }
}

export default injectIntl(CookiesConsent)
