import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'

const separatorClass = classNames({
  'footer-separator': true,
})

class Separator extends Component {

  render() {
    return (
      <div className={separatorClass} />
    )
  }
}

export default Separator
