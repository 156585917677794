import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const copyrightClass = classNames({
  'footer-copyright': true,
})

class Copyright extends Component {

  render() {
    const {
      text,
    } = this.props

    return (
      <p className={copyrightClass}>
        {text}
      </p>
    )
  }
}

Copyright.propTypes = {
  text: PropTypes.string.isRequired,
}

Copyright.defaultProps = {
  text: undefined,
}

export default Copyright
