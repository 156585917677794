import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Hidden } from 'react-grid-system'

import I18nLink from '../../../../components/I18nLink'
import Image from '../../../../components/Image'
import Inline from '../../../../components/Inline'
import Space from '../../../../components/Space'

import chevronDownBluePicture from '../../../../img/icons/icon_chevron_down_blue.svg'
import chevronDownWhitePicture from '../../../../img/icons/icon_chevron_down_white.svg'

class Item extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isToggled: false,
    }
  }

  componentDidMount() {
    if (this.props.children && this.titleElement && this.boxElement) {
      this.titleElement.addEventListener('mouseenter', this.open)
      this.titleElement.addEventListener('mouseleave', this.close)
      this.boxElement.addEventListener('mouseenter', this.open)
      this.boxElement.addEventListener('mouseleave', this.close)

      this.computeLeftPosition()
    }
  }

  componentWillUnmount() {
    if (this.titleElement && this.boxElement) {
      this.titleElement.removeEventListener('mouseenter', this.open)
      this.titleElement.removeEventListener('mouseleave', this.close)
      this.boxElement.removeEventListener('mouseenter', this.open)
      this.boxElement.removeEventListener('mouseleave', this.close)
    }
  }


  // Methods
  open = () => {
    this.setState({
      isToggled: true,
    })
    this.computeLeftPosition()
  }

  close = () => {
    this.setState({
      isToggled: false,
    })
  }

  computeLeftPosition = () => {
    if (this.titleElement && this.boxElement) {
      const titleWidth = this.titleElement.clientWidth
      const boxWidth = this.boxElement.clientWidth
      const offset = (boxWidth - titleWidth) / 2

      this.setState({
        dropdownLeftOffset: -Math.floor(offset),
      })
    }
  }


  // Render
  render() {
    const containerClass = classNames({
      'navigation-item-container': true,
    })

    return (
      <div className={containerClass}>
        {this.renderTitle()}
        <Hidden xs sm md>
          {this.renderDropdown()}
        </Hidden>
      </div>
    )
  }

  renderTitle = () => {
    const {
      text,
      uri,
      url,
      isReversed,
      children,
      newTab,
    } = this.props
    const titleContainerClass = classNames({
      'navigation-item-title-container': true,
      'navigation-item-title-container-reversed': isReversed,
    })

    return (
      <div
        className={titleContainerClass}
        ref={(input) => { this.titleElement = input }}
      >
        {
          uri ?
            (
              <I18nLink to={uri} alt={text}>
                {text}
              </I18nLink>
            ) :
            (
              <a href={url} target={newTab ? '_blank' : ''}>
                {text}
              </a>
            )
        }
        {children && (
          <Hidden xs sm md>
            <Inline type="xxs" />
            <Image
              alt="chevron"
              src={isReversed ? chevronDownWhitePicture : chevronDownBluePicture}
            />
          </Hidden>
        )}
      </div>
    )
  }

  renderDropdown = () => {
    const {
      isToggled,
      dropdownLeftOffset,
    } = this.state
    const {
      children,
    } = this.props

    const dropdownBoxClass = classNames({
      'navigation-item-dropdown-box': true,
    })
    const dropdownStripeClass = classNames({
      'navigation-item-dropdown-box-stripe': true,
    })

    return (
      <div
        style={{
          position: 'absolute',
          left: dropdownLeftOffset,
          visibility: isToggled ? 'visible' : 'hidden',
          zIndex: 100,
        }}
        ref={(input) => { this.boxElement = input }}
      >
        <Space type="xs" />
        <div className={dropdownStripeClass} />
        <div className={dropdownBoxClass}>
          {children}
        </div>
      </div>
    )
  }
}

Item.protoTypes = {
  text: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isReversed: PropTypes.bool,
  children: PropTypes.node,
  newTab: PropTypes.bool,
}

Item.defaultProps = {
  text: undefined,
  uri: undefined,
  url: undefined,
  isReversed: false,
  children: undefined,
  newTab: false,
}

export default Item
