import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'


const TYPES = [
  'thin',
  'xxs',
  'xs',
  's',
  'm',
  'l',
  'xl',
  'xxl',
  'giant',
]

class Space extends Component {

  render() {
    const {
      type,
    } = this.props
    const wuhaSpaceClass = classNames({
      'space': true,
      [`space-${type}`]: true,
    })

    return (
      <div className={wuhaSpaceClass} />
    )
  }
}

Space.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
}

Space.defaultProps = {
  type: TYPES[4],
}

export default Space
