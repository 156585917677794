import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Image from '../../../../components/Image'

import redBurgerIcon from '../../../../img/icons/icon_burger_red.svg'
import redCloseIcon from '../../../../img/icons/icon_close_red.svg'
import whiteBurgerIcon from '../../../../img/icons/icon_burger_white.svg'
import whiteCloseIcon from '../../../../img/icons/icon_close_white.svg'

class BurgerButton extends Component {

  render() {
    const {
      isToggled,
      isTransparent,
      onClick,
    } = this.props
    const burgerClass = classNames({
      'header-burgerbutton': true,
      'header-burgerbutton-transparent': isTransparent,
    })
    const imageSource = isToggled ?
      (isTransparent ? whiteCloseIcon : redCloseIcon) :
      (isTransparent ? whiteBurgerIcon : redBurgerIcon)

    return (
      <div
        className={burgerClass}
        onClick={onClick}
      >
        <Image
          alt="burger"
          src={imageSource}
          height={32}
        />
      </div>
    )
  }
}

BurgerButton.propTypes = {
  isToggled: PropTypes.bool,
  isTransparent: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

BurgerButton.defaultProps = {
  isToggled: false,
  isTransparent: false,
  onClick: undefined,
}

export default BurgerButton
