import React, { Component } from 'react'

import { PageContextProvider } from '../PageContext'

const withContext = (WrappedComponent) => {

  class HOC extends Component {

    render() {
      return (
        <PageContextProvider value={this.props.pageContext}>
          <WrappedComponent {...this.props} />
        </PageContextProvider>
      )
    }
  }

  return HOC
}

export default withContext
