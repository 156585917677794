module.exports = {

  // Meta
  'meta.home.title': 'Wuha | L’information à portée de main',
  'meta.home.description': 'Wuha centralise vos sources d’information pour une recherche fluide et optimale. Retrouvez facilement vos informations parmi vos Emails, vos documents stockés sur votre ordinateur ou dans le Cloud, vos fonds documentaires…',
  'meta.features.title': 'Fonctionnalités | Wuha',
  'meta.features.description': 'Économisez jusqu’à 40% sur votre temps de recherche grâce aux fonctionnalités de Wuha. Son Intelligence Artificielle vous permet de rechercher en toute confidentialité et sécurité au sein de plusieurs sources d’information en même temps.',
  'meta.ai.title': 'Intelligence Artificielle | Wuha',
  'meta.ai.description': 'L’Intelligence Artificielle de Wuha vous permet une expérience de recherche hors du commun. Ne perdez plus votre temps à classer vos documents, vos emails ou à bien les nommer.',
  'meta.integrations.title': 'Intégrations | Wuha',
  'meta.integrations.description': 'Accédez au catalogue complet des sources d’information et d’applications. Connectez à Wuha vos sources de stockage, vos logiciels de conversations, vos fonds documentaires : Google Drive, DropBox, SharePoint, Outlook, Gmail, Editions Francis Lefebvre, Dalloz...',
  'meta.pricing.title': 'Prix | Wuha',
  'meta.pricing.description': 'Les tarifs de votre assistant de recherche : transparents, flexibles et équitables. Testez gratuitement pendant 30 jours sans carte bancaire requise.',
  'meta.documentation.title': 'Documentation | Wuha',
  'meta.documentation.description': 'La documentation sur les données chez Wuha. Toutes les informations sur comment notre Data Science traite vos données, sur nos mesures de sécurité ainsi que sur notre engagement vis à vis de vos données personnelles.',
  'meta.dataManagement.title': 'Gestion des données | Wuha',
  'meta.dataManagement.description': 'Découvrez toutes les informations sur la Data Science chez Wuha. Plongez dans notre Intelligence Artificielle pour comprendre les données qu’elle récupère et comment elle fonctionne pour vous restituer les résultats les plus pertinents.',
  'meta.dataProtection.title': 'Protection des données | Wuha',
  'meta.dataProtection.description': 'Connectez toutes vos sources d’information à Wuha en toute sécurité. La sécurité des données a été pensée à la genèse de Wuha. La structure de notre architecture système est fondée sur des silos étanches, sécurisés et hébergés en France et les données transitent chiffrées.',
  'meta.privacyPolicy.title': 'Politique de Confidentialité | Wuha',
  'meta.privacyPolicy.description': 'Connectez vos sources d’information en toute confidentialité. Wuha est conforme au RGPD et anonymise vos données. Vous avez la possibilité de récupérer et / ou supprimer vos données. Découvrez en toute transparence comment Wuha gère vos données personnelles.',
  'meta.terms.title': 'Mentions légales | Wuha',
  'meta.terms.description': 'Les mentions légales de votre assistant de recherche Wuha : les Conditions Générales d’Utilisation de Wuha, les Conditions Générales SaaS et la Politique de Confidentialité.',
  'meta.about.title': 'À propos | Wuha',
  'meta.about.description': 'Toute l’équipe Wuha met son cœur à l’ouvrage pour vous offrir la meilleure expérience de recherche. Faites connaissance avec tous les membres qui travaillent avec passion à Paris et à Lyon.',
  'meta.blog.title': 'Tout sur la recherche, l’IA, le SaaS et les nouveautés de Wuha | Blog Wuha',
  'meta.blog.description': 'Découvrez les dernières tendances sur la recherche, l’IA, la sécurisation des données, le SaaS et tous les conseils sur les innovations de l’assistant de recherche Wuha.',
  'meta.404.title': 'Page introuvable | Wuha',
  'meta.404.description': 'Page introuvable',

  // Message
  'meta.message.title': 'Wuha',
  'meta.message.description': '',
  'message.header.title': 'Wuha',
  'message.header.description': '',
  'message.body.1': 'Pour en savoir plus, veuillez nous contacter sur contact@wuha.io',

  // Cookies
  'cookies.action.accept': 'Accepter',
  'cookies.description': 'En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de Cookies pour réaliser des statistiques de visites. {knowMore}',
  'cookies.action.knowMore': 'En savoir plus',

  // Global: Navigation
  'navigation.links.features': 'Fonctionnalités',
  'navigation.links.ai': 'Intelligence Artificielle',
  'navigation.links.integrations': 'Intégrations',
  'navigation.links.pricing': 'Prix',
  'navigation.links.documentation': 'Documentation',
  'navigation.links.documentation.dataManagement': 'La vie d’une data chez Wuha',
  'navigation.links.documentation.dataProtection': 'La sécurité chez Wuha',
  'navigation.links.documentation.privacyPolicy': 'Notre engagement sur vos données',
  'navigation.links.about': 'À propos',
  'navigation.links.connection': 'Connexion',
  'navigation.button.tryIt': 'Tester gratuitement',

  // Global: Breadcrumb
  'breadcrumb.wuha': 'Wuha',
  'breadcrumb.documentation': 'Documentation',
  'breadcrumb.features': 'Fonctionnalités',
  'breadcrumb.ai': 'Intelligence Artificielle',
  'breadcrumb.integrations': 'Intégrations',
  'breadcrumb.pricing': 'Tarifs',
  'breadcrumb.about': 'A propos',
  'breadcrumb.blog': 'Blog',
  'breadcrumb.terms': 'Mentions légales',

  // Global: OptimizeWorkflow
  'optimizeWorkflow.title': 'Devenir plus productif',
  'optimizeWorkflow.description': 'Connectez vos sources d’information pour rechercher et partager facilement',
  'optimizeWorkflow.action.getStarted': 'Commencer',

  // Global: Security Mini
  'security.mini.title': 'Nous prenons la sécurité au sérieux.',
  'security.mini.action.readMore': 'En savoir plus',
  'security.mini.action.uri': '/documentation',

  // Global: Footer
  'footer.header.tagline': 'Votre collègue du futur',
  'footer.header.serviceStatus': 'Statut du service',
  'footer.header.copyright': '© 2018 Wuha',
  'footer.links.product.header': 'Produit',
  'footer.links.product.items.features': 'Fonctionnalités',
  'footer.links.product.items.ai': 'Intelligence Artificielle',
  'footer.links.product.items.integrations': 'Intégrations',
  'footer.links.product.items.pricing': 'Prix',
  'footer.links.product.items.dataManagement': 'La vie d’une donnée',
  'footer.links.product.items.dataProtection': 'La protection des données',
  'footer.links.company.header': 'Équipe',
  'footer.links.company.items.about': 'À propos',
  'footer.links.company.items.blog': 'Blog',
  'footer.links.company.items.pp': 'Politique de Confidentialité',
  'footer.links.company.items.tos': 'Mentions Légales',
  'footer.links.social.header': 'Social',
  'footer.links.language.header': 'Langage',
  'footer.links.language.items.english': 'English',
  'footer.links.language.items.french': 'Français',

  // 404: Content
  '404.content.title': '404',
  '404.content.description': 'Cette page n’existe pas... du coup il n’y a pas grand chose à faire ici 🐔.',
  '404.content.action.goBack': 'Retourner à l’accueil',

  // Home: Header
  'home.header.title': 'L’information à portée de main',
  'home.header.description': 'L’assistant de recherche qui se connecte à toutes vos sources d’information pour faciliter votre travail',
  'home.header.action.connect': 'Connecter vos sources',

  // Home: Customers
  'home.customers.description': 'Et plus de 500+ entreprises nous font confiance',
  'home.customers.action.readMore': 'Lire leur témoignage',

  // Home: Promise
  'home.promises.title': 'Comment Wuha optimise votre flux de travail',
  'home.promises.boost.title': 'Boostez votre productivité',
  'home.promises.boost.description': 'Vous cherchez une information? Un fichier ? Une conversation ? Un contact ? Wuha vous permet de ne plus vous éparpiller et d’optimiser en moyenne 7h par semaine !',
  'home.promises.find.title': 'Trouvez facilement vos informations',
  'home.promises.find.description': 'Connectez toutes vos sources d’information à Wuha pour ne chercher qu’à un seul endroit.',
  'home.promises.search.title': 'Recherchez où et quand vous en avez le plus besoin',
  'home.promises.search.description': 'Wuha s’intègre parfaitement dans votre flux de travail en y affichant directement les résultats de recherche.',

  // Home: Install
  'home.install.title': 'Comment ça marche ?',
  'home.install.steps.1.title': 'Installez l’extension Wuha',
  'home.install.steps.1.description': 'Ajoutez le plugin Wuha sur votre navigateur Chrome, Firefox ou Internet Explorer 11',
  'home.install.steps.1.action': 'Installer maintenant',
  'home.install.steps.1.uri': '/artificial-intelligence',
  'home.install.steps.2.title': 'Connectez vos sources d’information',
  'home.install.steps.2.description': 'Connectez en toute sécurité vos logiciels préférés à Wuha',
  'home.install.steps.2.action': 'En savoir plus',
  'home.install.steps.2.uri': '/integrations',
  'home.install.steps.3.title': 'Recherchez',
  'home.install.steps.3.description': 'Cherchez vos informations en toute confidentialité dans Google et de partout depuis votre navigateur avec le raccourci CTRL + ESPACE',
  'home.install.steps.3.action': 'En savoir plus',
  'home.install.steps.3.uri': '/artificial-intelligence',
  'home.install.steps.4.title': 'Accédez à toutes vos informations en une seconde',
  'home.install.steps.4.description': 'Notre puissante Intelligence Artificielle vous propose les résultats de recherche les plus adaptés et pertinents',
  'home.install.steps.4.action': 'En savoir plus',
  'home.install.steps.4.uri': '/artificial-intelligence',

  // Home: Press
  'home.press.title': 'Recommandé par la presse',
  'home.press.usineDigitaletitle': 'Usine Digitale',
  'home.press.forbes.title': 'Forbes',
  'home.press.maddyness.title': 'Maddyness',
  'home.press.villageJustice.title': 'Village de la Justice',

  // Home: Feedbacks
  'home.feedbacks.title': 'Recommandé par ses clients',
  'home.feedbacks.feedback.vm.name': 'Véronique Maugeri,',
  'home.feedbacks.feedback.vm.position': 'Chef de produit fonctionnel @Éditions Francis Lefebvre',
  'home.feedbacks.feedback.vm.quote': 'L’exploitation de la solution Wuha ouvre des perspectives inédites sur la pertinence de la recherche dès le navigateur web et la première requête Google',
  'home.feedbacks.feedback.cp.name': 'Christophe Plissier,',
  'home.feedbacks.feedback.cp.position': 'Directeur Administratif et Financier @GAC Group',
  'home.feedbacks.feedback.cp.quote': 'Grâce à Wuha, nos consultants peuvent désormais trouver efficacement toute la documentation technique située dans nos serveurs et échanges d’e-mails',
  'home.feedbacks.feedback.tb.name': 'Thomas Belhamri,',
  'home.feedbacks.feedback.tb.position': 'Co-fondateur @Boryl',
  'home.feedbacks.feedback.tb.quote': 'L’extension Gmail me sert beaucoup ! Typiquement, quand je pense avoir reçu un message par mail, avec Wuha je vois rapidement que je l’ai en fait reçu sur slack. C’est parfait !',

  // Home: Security
  'home.security.title': 'Nous prenons la sécurité au sérieux.',
  'home.security.privacy.title': 'Votre vie privée, notre responsabilité',
  'home.security.privacy.description': 'Wuha a été conçu dès le début pour protéger vos données sur le concept de Privacy by Design. Aussi la confidentialité de vos données est respectée et en sécurité.',
  'home.security.security.title': 'Une architecture sécurisée, notre priorité',
  'home.security.security.description': 'L’équipe Wuha met la sécurité au cœur du moteur de recherche. Nous faisons régulièrement auditer nos infrastructures et nos systèmes par des experts en cyber-sécurité pour garantir la confidentialité et la sécurité de vos informations.',
  'home.security.action.readMore': 'En savoir plus',
  'home.security.action.uri': '/documentation',

  // Features: Header
  'features.header.title': 'La recherche simplifiée',
  'features.header.description': 'Notre Intelligence Artificielle recherche à l’intérieur du contenu des documents de toutes vos sources d’information',
  'features.header.action.connect': 'Connecter vos sources',

  // Features: List
  'features.list.title': 'Les fonctionnalités clés',
  'features.list.ai.title': 'Une Intelligence Artificielle robuste',
  'features.list.ai.description': 'Grâce à sa technologie robuste basée sur le Machine Learning et l’Intelligence Artificielle, Wuha recherche dans les contenus des documents pour en restituer les résultats les plus pertinents.',
  'features.list.ai.action': 'En savoir plus',
  'features.list.ai.uri': '/artificial-intelligence',
  'features.list.sources.title': 'La gestion multi-sources',
  'features.list.sources.description': 'Connectez autant de sources d’information à Wuha que vous le souhaitez pour ne chercher qu’à un seul endroit.',
  'features.list.sources.action': 'Voir le catalogue',
  'features.list.sources.uri': '/integrations',
  'features.list.displays.title': 'Simple et accessible',
  'features.list.displays.description': 'Wuha s’intègre directement dans votre flux de travail, là où vous en avez le plus besoin.',
  'features.list.displays.action': 'Découvrir',
  'features.list.displays.uri': '/artificial-intelligence#ai-displays',
  'features.list.privacy.title': 'Confidentialité',
  'features.list.privacy.description': 'En cas de besoins ponctuels, vous pouvez désactiver à tout moment l’affichage de Wuha dans Google. Pour information, lorsque vous faites une recherche sur Google, les résultats affichés sur la droite par Wuha ne sont jamais accessibles et visibles par Google.',
  'features.list.privacy.action': 'En savoir plus',
  'features.list.privacy.uri': '/privacy-policy#19',
  'features.list.security.title': 'Système sécurisé',
  'features.list.security.description': 'Vos données sont chiffrées et hébergées en France dans des datacenters certifiés SOC1, SOC2, ISO27001.',
  'features.list.security.action': 'En savoir plus',
  'features.list.security.uri': '/data-protection',
  'features.list.ocr.title': 'OCR',
  'features.list.ocr.description': 'Recherchez dans le contenu de tous les documents numérisés, comme les photos, les PDF.',
  'features.list.correction.title': 'Auto correction',
  'features.list.correction.description': 'En cas de faute de frappe, Wuha vous propose une requête corrigée.',
  'features.list.completion.title': 'Auto completion',
  'features.list.completion.description': 'Au fur et à mesure de la saisie, des suggestions personnalisées de recherche vous sont proposées.',

  // Features: Gains
  'features.gains.title': 'En quoi Wuha améliore votre flux de travail',
  'features.gains.description': 'Parce que la recherche d’information est au coeur de la connaissance',
  'features.gains.1.description': '-40% de temps de recherche',
  'features.gains.1.alt': 'recherche',
  'features.gains.2.description': '30% de clics économisés',
  'features.gains.2.alt': 'clics',
  'features.gains.3.description': '500+ entreprises',
  'features.gains.3.alt': 'utilisateurs',
  'features.gains.4.description': '20+ Millions documents chiffrés',
  'features.gains.4.alt': 'chiffrage',

  // Artificial Intelligence: Header
  'ai.header.title': 'La puissance de notre Intelligence Artificielle',
  'ai.header.description': 'Après plus de 3 ans de Recherche & Développement, Wuha propose de nombreuses fonctionnalités pour vous offrir la meilleure expérience de recherche possible.',
  'ai.header.action.connect': 'Connecter vos sources',

  // Artificial Intelligence: Strengths
  'ai.strengths.smartDisplay.id': 'strengths-smart-preview',
  'ai.strengths.smartDisplay.alt': 'aperçu intelligent',
  'ai.strengths.smartDisplay.title': 'L’aperçu intelligent',
  'ai.strengths.smartDisplay.description': 'En un coup d’oeil, identifiez la source d’information, le format du fichier et le contenu des résultats.',
  'ai.strengths.filters.id': 'strengths-filters',
  'ai.strengths.filters.alt': 'filtres',
  'ai.strengths.filters.title': 'Les filtres',
  'ai.strengths.filters.description': 'Sélectionnez la source dans laquelle vous souhaitez cibler vos recherches.',
  'ai.strengths.datesAndFormats.alt': 'dates et formats',
  'ai.strengths.dates.id': 'strengths-dates',
  'ai.strengths.dates.title': 'La détection de dates',
  'ai.strengths.dates.description': 'Wuha comprend et interprète les informations temporelles. Ainsi vous pouvez chercher par dates plus ou moins approximatives.',
  'ai.strengths.formats.id': 'strengths-formats',
  'ai.strengths.formats.title': 'La recherche par format',
  'ai.strengths.formats.description': 'Affinez vos recherches en ciblant le format dans vos requêtes',
  'ai.strengths.similarResults.id': 'strengths-similar-results',
  'ai.strengths.similarResults.alt': 'résultats similaires',
  'ai.strengths.similarResults.title': 'L’affichage de résultats similaires',
  'ai.strengths.similarResults.description': 'Finies les questions autour de la "bonne version" d’un document ! Wuha vous restitue la plus récente ainsi que les résultats similaires associés.',
  'ai.strengths.nlp.id': 'strengths-language-interpretation',
  'ai.strengths.nlp.alt': 'interprétation du langage',
  'ai.strengths.nlp.title': 'L’interprétation du langage',
  'ai.strengths.nlp.description': 'Notre I.A. s’adapte au vocabulaire propre à votre profession. Wuha réussit à traduire vos acronymes, à détecter les noms, à identifier les groupes nominaux...',
  'ai.strengths.nlp.action.readMore': 'En savoir plus',
  'ai.strengths.nlp.action.uri': '/data-management#2',
  'ai.strengths.typology.id': 'document-typology',
  'ai.strengths.typology.alt': 'typology de document',
  'ai.strengths.typology.title': 'La typologie de document',
  'ai.strengths.typology.description': 'Ne renommez plus vos documents grâce à notre algorithme capable d’interpréter les typologies comme les CV, les contrats, les factures, les devis, etc.',

  // Artificial Intelligence: Displays
  'ai.displays.title': 'Wuha s’adapte à vos besoins',
  'ai.displays.description': 'Nous avons souhaité rendre l’information accessible, à tout moment',
  'ai.displays.bar.title': 'Depuis votre navigateur avec CTRL + ESPACE',
  'ai.displays.google.title': 'Depuis Google',
  'ai.displays.wuha.title': 'Depuis l’application en ligne de Wuha',
  'ai.displays.slack.title': 'Depuis l’application Wuha de Slack',

  // Artificial Intelligence: DisplaysConfig
  'ai.displaysConfig.description': 'Choisissez où vous souhaitez faire apparaître précisément les résultats dans votre flux de travail',
  'ai.displaysConfig.action.seeIntegrations': 'Voir toutes les autres fonctionnalités',
  'ai.displaysConfig.action.uri': '/integrations',

  // Integrations: Header
  'integrations.header.title': 'Intégrations',
  'integrations.header.description': 'Wuha se connecte à des solutions uniquement Cloud, et aussi à votre ordinateur Mac ou PC.',
  'integrations.header.action.connect': 'Connecter vos sources',

  // Integrations: Sources
  'integrations.sources.storage.title': 'Des sources de stockage',
  'integrations.sources.storage.description': 'Recherchez en toute sécurité dans vos documents, vos photos, vidéos et plus encore avec Wuha en fonction de :',
  'integrations.sources.storage.point.1': 'leurs noms et leurs contenus',
  'integrations.sources.storage.point.2': 'la date à laquelle ils ont été créés ou modifiés pour la dernière fois',
  'integrations.sources.storage.point.3': 'leurs formats, comme PDF, DOC, PowerPoint etc.',
  'integrations.sources.storage.point.4': 'leurs auteurs et contributeurs',
  'integrations.sources.messaging.title': 'Des logiciels de conversations',
  'integrations.sources.messaging.description': 'Vous ne savez plus où vous en avez discuté ? Recherchez en toute sécurité dans tous vos e-mails et pièces jointes avec Wuha selon :',
  'integrations.sources.messaging.point.1': 'leur sujet, leurs étiquettes ou leur contenu',
  'integrations.sources.messaging.point.2': 'le format des pièces jointes, comme PDF, DOC, PowerPoint etc',
  'integrations.sources.messaging.point.3': 'la date à laquelle l’e-mail a été envoyé ou reçu',
  'integrations.sources.messaging.point.4': 'l’expéditeur, les destinataires ou d’autres personnes dans la chaîne d’échange de l’e-mail',
  'integrations.sources.legal.title': 'Des logiciels juridiques',
  'integrations.sources.legal.description': 'Accédez simplement à vos connaissances à travers des fonds documentaires pluridisciplinaires qui vous proposent une vision complète du droit. Sécurisez vos décisions en recherchant directement dans :',
  'integrations.sources.legal.point.1': 'les Matières qui vous concernent',
  'integrations.sources.legal.point.2': 'les Mementos & fonds documentaires juridiques commentés et analysés',
  'integrations.sources.legal.point.3': 'les Revues & toute l’actualité juridique, fiscale, sociale, comptable, immobilière, du patrimoine, de la famille et des associations',
  'integrations.sources.legal.point.4': 'les Cas pratiques',
  'integrations.sources.legal.point.5': 'les Modèles',
  'integrations.sources.box.title': 'Box',
  'integrations.sources.dropbox.title': 'DropBox',
  'integrations.sources.gdrive.title': 'Google Drive',
  'integrations.sources.msonedrive.title': 'Microsoft OneDrive',
  'integrations.sources.mssharepoint.title': 'Microsoft SharePoint',
  'integrations.sources.fs_macos.title': 'MacOS',
  'integrations.sources.fs_mswindows.title': 'Microsoft Windows',
  'integrations.sources.slack.title': 'Slack',
  'integrations.sources.msoutlook.title': 'Microsoft Outlook',
  'integrations.sources.gmail.title': 'Gmail',
  'integrations.sources.efl.title': 'Editions Francis Lefèbvre',
  'integrations.sources.dalloz.title': 'Dalloz Avocats',

  // Integrations: Incoming
  'integrations.incoming.title': 'Les lancements à venir',
  'integrations.incoming.description': 'Pour votre plus grand plaisir, notre équipe travaille assidûment au développement des prochaines applications',
  'integrations.incoming.gcalendar.title': 'Google Calendar',
  'integrations.incoming.el.title': 'Éditions Législatives',
  'integrations.incoming.asana.title': 'Asana',
  'integrations.incoming.quickbooks.title': 'Quickbooks',
  'integrations.incoming.salesforce.title': 'Salesforce',
  'integrations.incoming.evernote.title': 'Evernote',
  'integrations.incoming.trello.title': 'Trello',

  // Documentation: Header
  'documentation.header.title': 'Documentation',
  'documentation.header.description': 'Wuha a été conçu dès le début pour protéger vos données sur le concept de Privacy by Design',

  // Documentation: Summary
  'documentation.links.dataManagement': 'La vie d’une data chez Wuha',
  'documentation.links.dataProtection': 'La sécurité chez Wuha',
  'documentation.links.privacyPolicy': 'Notre engagement sur vos données',
  'documentation.anchors.dataManagement.1': 'Qu’est-ce que Wuha récupère ?',
  'documentation.anchors.dataManagement.2': 'Vos données pour le meilleur des résultats',
  'documentation.anchors.dataProtection.1': 'Nos actions clés de protection de vos données',
  'documentation.anchors.dataProtection.2': 'Où sont stockées vos données ?',
  'documentation.anchors.dataProtection.3': 'Comment vos données sont protégées chez nous ?',
  'documentation.anchors.dataProtection.4': 'Zoom sur notre structure multi-couche',
  'documentation.anchors.dataProtection.5': 'Notre infrastructure testée et éprouvée',
  'documentation.anchors.privacyPolicy.1': 'Notre responsabilité',
  'documentation.anchors.privacyPolicy.2': 'Votre responsabilité',
  'documentation.anchors.privacyPolicy.3': 'À propos de cette Politique Données Personnelles',
  'documentation.anchors.privacyPolicy.4': 'Définitions',
  'documentation.anchors.privacyPolicy.5': 'Quand est-ce que cette Politique Données Personnelles ne s’applique pas ?',
  'documentation.anchors.privacyPolicy.6': 'Comment puis-je vous contacter ?',
  'documentation.anchors.privacyPolicy.7': 'Quelles sont les informations que nous recueillons sur vous ?',
  'documentation.anchors.privacyPolicy.8': 'Pourquoi certaines informations sont obligatoires à fournir ?',
  'documentation.anchors.privacyPolicy.9': 'Pourquoi utilisez-vous mes informations ?',
  'documentation.anchors.privacyPolicy.10': 'Pendant combien de temps conservez-vous mes informations ?',
  'documentation.anchors.privacyPolicy.11': 'Effectuez-vous du profilage sur mes informations ?',
  'documentation.anchors.privacyPolicy.12': 'À qui sont communiquées mes informations ?',
  'documentation.anchors.privacyPolicy.13': 'Comment sécurisons-nous vos informations ?',
  'documentation.anchors.privacyPolicy.14': 'Où sont stockées mes informations ?',
  'documentation.anchors.privacyPolicy.15': 'Utilisez-vous des cookies ?',
  'documentation.anchors.privacyPolicy.16': 'Comment puis-je m’opposer à l’utilisation des cookies ?',
  'documentation.anchors.privacyPolicy.17': 'Quels sont mes droits ?',
  'documentation.anchors.privacyPolicy.18': 'Comment exercer mes droits ?',

  // DocumentationDetail: Navigation
  'documentation.dataManagement': 'La vie d’une data chez Wuha',
  'documentation.dataProtection': 'La sécurité chez Wuha',
  'documentation.privacyPolicy': 'Notre engagement sur vos données',

  // Pricing: Header
  'pricing.header.title': 'Un prix qui s’adapte à vos besoins',
  'pricing.header.description.1': 'Transparent. Flexible. Équitable.',
  'pricing.header.description.2': 'Testez gratuitement pendant 30 jours. Carte bancaire non requise.',

  // Pricing: Offers
  'pricing.offers.toggle.yearly.title': 'Annuel',
  'pricing.offers.toggle.monthly.title': 'Mensuel',
  'pricing.offers.card.free.title': 'Gratuit',
  'pricing.offers.card.free.price': '0€',
  'pricing.offers.card.free.button': 'S’inscrire gratuitement',
  'pricing.offers.card.standard.title': 'Standard',
  'pricing.offers.card.standard.price': '{price}{currency} HT',
  'pricing.offers.card.standard.caption': 'par utilisateur, par mois',
  'pricing.offers.card.standard.button': 'Tester 30 jours gratuitement',
  'pricing.offers.card.business.title': 'Entreprise',
  'pricing.offers.card.business.price': 'Contactez-nous',
  'pricing.offers.card.business.button': 'Contactez-nous',
  'pricing.offers.card.features.allMain': 'Toutes les fonctionnalités principales',
  'pricing.offers.card.features.see': 'Voir',
  'pricing.offers.card.features.free.indexing': 'Indexation de données : 10Go',
  'pricing.offers.card.features.free.history': 'Historique de recherche : 30 jours',
  'pricing.offers.card.features.standard.indexing': 'Indexation de données : Illimitée',
  'pricing.offers.card.features.standard.history': 'Historique de recherche : Illimité',
  'pricing.offers.card.features.standard.fs': 'Indexation Finder Mac et Windows Explorer',
  'pricing.offers.card.features.business.customApps': 'Applications sur mesure',
  'pricing.offers.card.features.business.dedicatedSupport': 'Support dédié',
  'pricing.offers.card.features.business.personalizedSupport': 'Accompagnement personnalisé',
  'pricing.offers.card.features.business.analytics': 'Rapport et analyse des données sur mesure',

  // Pricing: Features
  'pricing.features.title': 'Fonctionnalités principales inclues dans chaque offre',
  'pricing.features.1': 'Connexion à toutes les applications',
  'pricing.features.2': 'Algorithme de recherche intelligent',
  'pricing.features.3': 'Multi-comptes par application',
  'pricing.features.4': 'Indexation limitées de données (variable)',
  'pricing.features.5': 'Prévisualisation du contenu enrichi',
  'pricing.features.6': 'Historique de recherche (variable)',
  'pricing.features.7': 'Sécurisation et chiffrement des données',
  'pricing.features.8': 'Multiples modes d’affichage',

  // Pricing: Keywords
  'pricing.faq.questions.plan.free': 'Gratuit',
  'pricing.faq.questions.plan.standard': 'Standard',
  'pricing.faq.questions.keyword.here': 'ici',
  'pricing.faq.questions.keyword.knowMore': 'En savoir plus',
  'pricing.faq.questions.keyword.privacy': 'Confidentialité',
  'pricing.faq.questions.keyword.theExtension': 'l’extension',
  'pricing.faq.questions.keyword.google': 'Google',
  'pricing.faq.questions.keyword.connectedApps': ' sources connectées',

  // Pricing: QA
  'pricing.faq.title': 'Questions courantes',

  // Pricing: QA -> Price
  'pricing.faq.price.title': 'Tarifs',
  'pricing.faq.price.1.question': 'Dans le plan Free, l’historique de recherche à 30 jours joue sur les documents créés ou modifiés il y a 30 jours ?',
  'pricing.faq.price.1.answer': 'Dans le plan Free, vous avez seulement accès aux résultats de recherche sur les e-mails, les documents et informations modifiés dans les 30 derniers jours.',
  'pricing.faq.price.2.question': 'Est-ce que les coordonnées bancaires sont requises pendant la période de test ?',
  'pricing.faq.price.2.answer': 'Non. Pour des raisons éthiques, nous tenons à ce que l’utilisateur soit libre de tester et d’utiliser gratuitement Wuha sans avoir à saisir ses coordonnées bancaires au préalable.',
  'pricing.faq.price.3.question': 'Est-ce que l’abonnement est en reconduction automatique ?',
  'pricing.faq.price.3.answer': 'Oui. Que ce soit pour l’abonnement Mensuel ou Annuel du Plan Standard, le prélèvement des mensualités est automatiquement reconduit.',
  'pricing.faq.price.4.question': 'Est-ce que je peux me désabonner à tout moment ?',
  'pricing.faq.price.4.answer': 'Oui bien entendu. Vous pouvez vous désabonner et vous réabonner en un clic dans votre compte. Une fois désabonné, vous avez accès aux fonctionnalités du plan Standard jusqu’à la fin du mois en cours.',
  'pricing.faq.price.5.question': 'Est-ce que je peux utiliser Wuha gratuitement ?',
  'pricing.faq.price.5.answer': 'Bien sûr ! Vous pouvez l’utiliser au quotidien gratuitement en souscrivant au plan {freePlan}.',
  'pricing.faq.price.6.question': 'Que se passe-t-il après le mois gratuit du plan Standard ',
  'pricing.faq.price.6.answer': 'Après un mois d’essai gratuit, vous aurez le choix entre :',
  'pricing.faq.price.6.answer.1': 'Rester dans le plan {standardPlan} pour 7€ HT par mois',
  'pricing.faq.price.6.answer.2': 'Choisir le plan {freePlan}',

  // Pricing: QA -> Data
  'pricing.faq.data.title': 'Données',
  'pricing.faq.data.1.question': 'Comment mes données sont-elles sécurisées ?',
  'pricing.faq.data.1.answer': 'Notre site a été conçu sur le principe du Privacy by Design. Aussi notre architecture système est organisée en silos étanches, autonomes et sécurisés ce qui empêche, en cas d’attaque, l’exploitation des données. Notre dernier audit par Thalès CyberSécurité a ainsi reçu la note de 4,98/5.',
  'pricing.faq.data.2.question': 'Comment mes données personnelles sont-elles utilisées ?',
  'pricing.faq.data.2.answer': 'En toute transparence, retrouvez {link} comment nous traitons vos données personnelles.',
  'pricing.faq.data.3.question': 'Comment fonctionne Wuha pour me restituer des résultats pertinents ?',
  'pricing.faq.data.3.answer': 'Afin de vous proposer le meilleur résultat de recherche, Wuha a besoin d’avoir accès au contenu et aux métadonnées de vos documents et de vos e-mails. Une fois ces informations indexées, l’algorithme développé par nos équipes, analyse les données stockées et en ressort les résultats les plus adéquats en fonction de critères pré-établis.',
  'pricing.faq.data.4.question': 'Est-ce que Wuha conserve les restrictions d’accès aux dossiers/fichiers ?',
  'pricing.faq.data.4.answer': 'Tout à fait. Par exemple, si vous n’avez accès qu’à certains dossiers dans Google Drive, alors Wuha n’indexera que ces dossiers. Toute la confidentialité au sein des équipes est alors conservée. C’est valable pour toutes les applications qui supportent ce type de restriction (SharePoint, Mon Ordinateur, Dropbox, etc...)',
  'pricing.faq.data.5.question': 'Est-ce que je peux supprimer mes données de Wuha ?',
  'pricing.faq.data.5.answer': 'Bien entendu, vous pouvez supprimer vos données directement depuis votre compte > {link}',
  'pricing.faq.data.6.question': 'Est-ce que Google a accès à mes données ?',
  'pricing.faq.data.6.answer': 'Non. Lorsque vous faites une recherche sur Google, les résultats affichés sur la droite par Wuha ne sont pas accessibles et visibles par Google.',

  // Pricing: QA -> Product
  'pricing.faq.product.title': 'Produit',
  'pricing.faq.product.1.question': 'Est-ce que Wuha est un logiciel ou une application en ligne ?',
  'pricing.faq.product.1.answer': 'Wuha est une solution disponible en ligne. Elle dispose de plusieurs modes d’utilisation :',
  'pricing.faq.product.1.answer.1': 'sur la Web App : {link}',
  'pricing.faq.product.1.answer.2': 'après avoir connecté {link}',
  'pricing.faq.product.1.answer.2.1': 'à droite des résultats du moteur de recherche {link}',
  'pricing.faq.product.1.answer.2.2': 'n’importe où depuis votre navigateur Chrome, Firefox ou Internet Explorer 11 avec le raccourci CTRL + ESPACE',
  'pricing.faq.product.1.answer.3': 'Seule l’application "Mon ordinateur" requiert le téléchargement d’un logiciel pour synchroniser vos documents avec Wuha',
  'pricing.faq.product.2.question': 'Est-ce que Wuha fonctionne sur mobile ?',
  'pricing.faq.product.2.answer': 'Oui, Wuha est disponible sur mobile sur {link}',
  'pricing.faq.product.3.question': 'Quelle est la liste des applications que je peux connecter ?',
  'pricing.faq.product.3.answer': 'Une application est une solution en ligne que vous utilisez comme Google Drive, Gmail, Dropbox etc.',
  'pricing.faq.product.3.answer.1': 'La liste exhaustive est disponible {link}',
  'pricing.faq.product.4.question': 'Est-ce que Wuha présente les différentes versions d’un document ?',
  'pricing.faq.product.4.answer': 'Oui grâce à son algorithme, Wuha détecte les documents similaires et vous les restitue clairement dans les résultats de recherche.',

  // About: Header
  'about.header.title': 'A propos de nous',
  'about.header.description.1': 'Au cours de nos expériences professionnelles respectives, nous étions frustrés de perdre autant de temps à rechercher des renseignements à travers toutes ces sources d’information : CRM, serveurs de fichiers, Intranet, e-mails et plein d’autres. Nous savions que cela n’avait aucune valeur ajoutée.',
  'about.header.description.2': 'C’est pour cette raison que nous avons créé Wuha : nous souhaitons que chaque collaborateur ait accès facilement à l’information là où il en a besoin, quand il en a besoin pour qu’il puisse se concentrer sur l’essentiel.',
  'about.header.signature': 'Sacha | Antonin | Steven',

  // About: Values
  'about.values.title': 'Les valeurs qui nous guident',
  'about.values.transparency.title': 'Transparence',
  'about.values.transparency.description': 'Nous pensons qu’être totalement transparent avec nos équipes et auprès de nos clients nous permet d’avancer plus rapidement dans la résolution de problèmes et de prendre pleinement nos responsabilités.',
  'about.values.teamspirit.title': 'L’esprit d’équipe',
  'about.values.teamspirit.description': 'Nous sommes persuadés que c’est la force de notre équipe qui nous permettra de toujours se réinventer. L’esprit d’équipe, c’est aussi compter les uns sur les autres et se serrer les coudes dans les moments intenses. ',
  'about.values.expectations.title': 'L’exigence',
  'about.values.expectations.description': 'L’implication et l’engagement de chacun nous permet d’entreprendre chaque challenge dans une dynamique constructive pour toujours répondre au mieux aux attentes de nos utilisateurs.',

  // About: Team
  'about.team.title': 'Rencontrons-nous',
  'about.team.members.sacha.title': 'CEO & Cofondateur',
  'about.team.members.sacha.description': 'J’aimerais que Wuha me trouve un permis de conduire',
  'about.team.members.antonin.title': 'CMO & Cofondateur',
  'about.team.members.antonin.description': 'J’aimerais que Wuha me trouve une place pour me garer en ville',
  'about.team.members.steven.title': 'CTO & Cofondateur',
  'about.team.members.steven.description': 'J’aimerais que Wuha me trouve mon vélo volé',
  'about.team.members.juliette.title': 'Growth Marketer',
  'about.team.members.juliette.description': 'J’aimerais que Wuha me trouve des M&M’s light, bio et fabriqués à Lyon',
  'about.team.members.charly.title': 'Customer Success Manager',
  'about.team.members.charly.description': 'J’aimerais que Wuha me trouve un huitième jour dans la semaine',
  'about.team.members.alexis.title': 'Sales Engineer',
  'about.team.members.alexis.description': 'J’aimerais que Wuha me trouve les numéros du prochain LOTO',
  'about.team.members.timothee.title': 'Lead Data Scientist',
  'about.team.members.timothee.description': 'J’aimerais que Wuha me trouve des victoires de l’OL',
  'about.team.members.yohann.title': 'Data Scientist',
  'about.team.members.yohann.description': 'J’aimerais que Wuha me trouve une photo de mon ancienne prof de musique de 5ème',
  'about.team.members.raphael.title': 'Data Scientist',
  'about.team.members.raphael.description': 'J’aimerais que Wuha me trouve où est caché le stock de bonbons de Charly',
  'about.team.members.kassim.title': 'Data Scientist',
  'about.team.members.kassim.description': 'J’aimerais que Wuha me trouve le meilleur tacos de Lyon',
  'about.team.members.aymeric.title': 'Software Engineer',
  'about.team.members.aymeric.description': 'J’aimerais que Wuha me trouve des nouvelles blagues',
  'about.team.members.maxime.title': 'Software Engineer',
  'about.team.members.maxime.description': 'J’aimerais que Wuha me trouve un listing des meilleurs projets Kickstarter et Indiegogo',
  'about.team.members.jerome.title': 'Systems Engineer',
  'about.team.members.jerome.description': 'J’aimerais que Wuha me trouve...',

  // About: Location
  'about.location.title': 'Où la magie opère',
  'about.location.lyon.title': 'À Lyon',
  'about.location.lyon.address': '40 rue Laure Diebold - 69009 Lyon, France',
  'about.location.paris.title': 'À Paris',
  'about.location.paris.address': 'Station F : 5 Parvis Alan Turing, Paris 13ème',
  'about.location.contact': 'Vous pouvez nous joindre à tout moment à {email} ou par {chat}',

  // Blog: Header
  'blog.header.title': 'Blog',
  'blog.header.description': 'Toute l’actualité de Wuha',

  // Blog: Post
  'blog.posts.post.authorDate': '{name} a posté le {date}',
  'blog.posts.post.action.readMore': 'En lire plus',

  // Terms: Header
  'terms.header.title': 'Mentions légales',
  'terms.header.description': 'Toutes les mentions légales sur l’hébergement et les conditions générales de Wuha',

  // Terms: Content
  'terms.content.here': 'ici',
  'terms.content.company.title': 'Wuha au capital de 8 740 €',
  'terms.content.company.rcs': 'N° RCS: 810 665 273',
  'terms.content.company.tva': 'N° TVA: FR 94 810 665 273',
  'terms.content.company.editor': 'Editeur : Sacha Gallo Parouty',
  'terms.content.hosting.title': 'OVH',
  'terms.content.hosting.street': '2 rue Kellermann',
  'terms.content.hosting.city': '59100 Roubaix',
  'terms.content.hosting.country': 'France',
  'terms.content.cnil.title': 'Déclaration CNIL',
  'terms.content.cnil.description': 'Déclaration CNIL numéro : 1920582 v 0',
  'terms.content.tou.title': 'Conditions Générales d’Utilisation',
  'terms.content.tou.description': 'Vous pouvez télécharger les conditions générales d’utilisation {link}.',
  'terms.content.saas.title': 'Conditions Générales d’Utilisation SaaS',
  'terms.content.saas.description': 'Vous pouvez télécharger les conditions générales d’utilisation SaaS {link}.',
  'terms.content.pp.title': 'Politique de Confidentialité',
  'terms.content.pp.description': 'Vous pouvez accéder à notre politique de confidentialité {link}.',

  // Enterprise
  'enterprise.meta.title': 'Wuha pour l\'entreprise',
  'enterprise.meta.description': 'Wuha aide les entreprises à construire et deployer des applications de recherche sur mesure et puissantes. Avec notre technologie, vous pouvez créer votre propre experience de recherche pour fidéliser vos utilisateurs et vos clients. Vos données restent au sein de votre organisation pour en garantir leur confidentialité.',

  'enterprise.meta.knowledgeManagers.title': 'Knowledge Managers | Wuha',
  'enterprise.meta.knowledgeManagers.description': 'Wuha aide les entreprises à fournir les meilleurs services pour leurs employés afin d\'améliorer leur productivité et maximiser l\'utilisation de l\'information interne. Wuha a été créé pour donner le pouvoir de la Recherche pour les Entreprise en gardant toutes leurs données en interne.',

  'enterprise.meta.contentCreators.title': 'Éditeurs de contenus | Wuha',
  'enterprise.meta.contentCreators.description': 'Wuha aide les éditeurs de contenus à valoriser leurs données avec des applications de recherche. Avec Wuha, les entreprises sont capables à fournir toutes les fonctionnalités d\'un moteur de recherche puissant sans besoin d\'expertise technique. Wuha vous aide à maximiser le potentiel de vos contenus sans qu\'ils ne quittent votre organisation.',
  'enterprise.meta.searchExpertise.title': 'Notre expertise | Wuha',
  'enterprise.meta.searchExpertise.description': 'Utilisez les années d\'experience de Wuha pour construire votre propre moteur de recherche intelligent. Notre expertise dans la recherche et le machine learning est à votre portée',

  'breadcrumb.enterprise': 'Entreprise',
  'breadcrumb.enterprise.knowledgeManagers': 'Knowledge Managers',
  'breadcrumb.enterprise.contentCreators': 'Éditeurs de contenus',
  'breadcrumb.enterprise.searchExpertise': 'Notre expertise',

  'enterprise.navigation.button.contactUs': 'Contactez-nous',

  'enterprise.footer.header.tagline': 'Solution de recherche pour les entreprises',
  'enterprise.footer.links.solutions.header': 'Solutions',
  'enterprise.footer.links.solutions.items.forContentCreators': 'Éditeurs de contenus',
  'enterprise.footer.links.solutions.items.forKnowledgeManagers': 'Knowledge Managers',
  'enterprise.footer.links.solutions.items.searchExpertise': 'Notre Expertise',
  'enterprise.footer.links.solutions.items.apiDocumentation': 'Documentation',

  'enterpriseNavigation.links.solutions': 'Solutions',
  'enterpriseNavigation.links.contentCreators': 'Éditeurs de contenus',
  'enterpriseNavigation.links.knowledgeManagers': 'Knowledge Managers',
  'enterpriseNavigation.links.ourExpertise': 'Notre Expertise',
  'enterpriseNavigation.links.apiDocumentation': 'Documentation',

  'enterprise.header.id': 'search',
  'enterprise.header.title': 'Utiliser la puissance d\'un moteur de recherche interne au sein de votre entreprise',
  'enterprise.header.description': 'Wuha aide les entreprises à construire et deployer des applications de recherche sur mesure et puissants. Avec Wuha, nos clients creént des experiences qui fidélisent leurs utilisateurs en gardant la confidentialité de leurs informations. Vos données restent au sein de votre organisation.',
  'enterprise.header.action.connect': 'Contactez-nous',

  'enterprise.contentCreators.header.id': 'engage',
  'enterprise.contentCreators.header.title': 'Utiliser la recherche pour créer une expérience qui fidélise vos utilisateurs',
  'enterprise.contentCreators.header.description': 'Wuha vous aide à valoriser vos contenus avec des applications de recherche à haute valeur ajoutée. Nous sommes capables de vous fournir toutes les fonctionnalités d\'une puissante IA de recherche pour que vous puissiez facilement créer votre propre moteur et en maximiser le potentiel vis à vis de vos utilisateurs.',
  'enterprise.contentCreators.header.action.connect': 'Contactez-nous',

  'enterprise.contentCreators.arguments.costTraining.id': 'search_expert',
  'enterprise.contentCreators.arguments.costTraining.alt': 'Votre expert de recherche',
  'enterprise.contentCreators.arguments.costTraining.title': 'Votre expert de recherche',
  'enterprise.contentCreators.arguments.costTraining.description': 'Wuha est une équipe d’expert en développement et data science qui crée depuis près de 4 ans une solution intelligente de recherche d’information. Notre équipe vous propose aujourd’hui des fonctionnalités complexes d’extraction et de gestion des données dans un format compréhensible et facile à utiliser.',

  'enterprise.contentCreators.arguments.complicated.id': 'complexity',
  'enterprise.contentCreators.arguments.complicated.alt': 'Construire facilement un moteur de recherche intelligent',
  'enterprise.contentCreators.arguments.complicated.title': 'Construire facilement un moteur de recherche intelligent',
  'enterprise.contentCreators.arguments.complicated.description': 'Wuha traite et rend accessible pour les entreprises des sujets complexes comme le NLP et le NER.',

  'enterprise.contentCreators.arguments.control.id': 'build',
  'enterprise.contentCreators.arguments.control.alt': 'Construire votre propre moteur de recherche',
  'enterprise.contentCreators.arguments.control.title': 'Construire votre propre moteur de recherche',
  'enterprise.contentCreators.arguments.control.description': 'Vous pouvez customiser votre moteur de recherche avec les règles de pertinence que vous voulez. Notre but est de fournir un moteur performant sur lequel aurez toujours la possibilité de modifier les poids, les traitements, et créer des règles spécifiques en fonction de vos besoins.',

  'enterprise.contentCreators.arguments.dataSecurity.id': 'security',
  'enterprise.contentCreators.arguments.dataSecurity.alt': 'Vos données restent chez vous',
  'enterprise.contentCreators.arguments.dataSecurity.title': 'Vos données restent chez vous',
  'enterprise.contentCreators.arguments.dataSecurity.description': 'Wuha est déployé dans votre organisation pour pouvoir contrôler de A à Z les flux de données. Aucune donnée ne sort de vos serveurs, vous pourrez indexer et rechercher de l\'information sensible en toute confidentialité.',

  'enterprise.knowledgeManagers.header.id': 'productivity',
  'enterprise.knowledgeManagers.header.title': 'Boostez la productivité de vos collaborateurs en créant facilement votre propre moteur de recherche',
  'enterprise.knowledgeManagers.header.description': 'Wuha aide les entreprises à fournir les meilleurs outils pour leurs employés en améliorant leur productivité et en maximisant l\'utilisation de l\'information interne. Wuha a été crée pour donner le pouvoir de la Recherche pour les Entreprise en gardant toutes leurs données en interne.',
  'enterprise.knowledgeManagers.header.action.connect': 'Contactez-nous',

  'enterprise.knowledgeManagers.arguments.costTraining.id': 'search_expert',
  'enterprise.knowledgeManagers.arguments.costTraining.alt': 'Votre expert de recherche',
  'enterprise.knowledgeManagers.arguments.costTraining.title': 'Votre expert de recherche',
  'enterprise.knowledgeManagers.arguments.costTraining.description': 'Wuha utilise ses années d\'experience dans la recherche pour vous présenter les fonctionnalités complexes dans un format compréhensible et facile à utiliser.',

  'enterprise.knowledgeManagers.arguments.control.id': 'customise',
  'enterprise.knowledgeManagers.arguments.control.alt': 'Une performance configurable et contrôlée',
  'enterprise.knowledgeManagers.arguments.control.title': 'Une performance configurable et contrôlée',
  'enterprise.knowledgeManagers.arguments.control.description': 'Avec Wuha vous pouvez customiser votre moteur de recherce avec les règles de pertinence que vous voulez. Notre but est de fournit un moteur performant sans configuration specifique, mais vous avez toujours la possibilité de modifier les poids, traitements, et regles spécifiques comme vous le souhaitez.',

  'enterprise.knowledgeManagers.arguments.complicated.id': 'complexity',
  'enterprise.knowledgeManagers.arguments.complicated.alt': 'Construire facilement des moteur de recherche intelligents',
  'enterprise.knowledgeManagers.arguments.complicated.title': 'Construire facilement des moteur de recherche intelligents',
  'enterprise.knowledgeManagers.arguments.complicated.description': 'Wuha traite et rend accessible pour les entreprises des sujets complexes comme le NLP et le NER.',

  'enterprise.knowledgeManagers.arguments.dataSecurity.id': 'security',
  'enterprise.knowledgeManagers.arguments.dataSecurity.alt': 'Vos données restent chez vous',
  'enterprise.knowledgeManagers.arguments.dataSecurity.title': 'Vos données restent chez vous',
  'enterprise.knowledgeManagers.arguments.dataSecurity.description': 'Wuha est déployé dans votre organisation pour pouvoir contrôler de A à Z les flux de données. Aucune donnée ne sort de vos serveurs, vous pourrez indexer et rechercher de l\'information sensible en toute confidentialité.',

  'enterprise.searchExpertise.header.id': 'complexity',
  'enterprise.searchExpertise.header.title': 'Maîtrisez la complexité de la recherche',
  'enterprise.searchExpertise.header.description': 'Utilisez les années d\'experience de Wuha pour construire votre propre moteur de recherche intelligent. Notre expertise dans la recherche et le machine learning est à votre portée',
  'enterprise.searchExpertise.header.action.connect': 'Contactez-nous',

  'enterprise.searchExpertise.lowLevelExpertise.id': 'integrated',
  'enterprise.searchExpertise.lowLevelExpertise.alt': 'Integré avec ElasticSearch',
  'enterprise.searchExpertise.lowLevelExpertise.title': 'Integré avec ElasticSearch',
  'enterprise.searchExpertise.lowLevelExpertise.description': 'Une formation complète sur ElasticSearch prend du temps. Nous avons passé des années à travailler avec leur technologie et utilisons les meilleures pratiques pour implémenter les fonctionnalités de Wuha.',

  'enterprise.searchExpertise.scalable.id': 'scalable',
  'enterprise.searchExpertise.scalable.alt': 'Scalable et rapide',
  'enterprise.searchExpertise.scalable.title': 'Scalable et rapide',
  'enterprise.searchExpertise.scalable.description': 'La recherche doit être instantanée. Wuha et ElasticSearch se mettent à l\'échelle de vos besoins de recherche. Les deux technologies ont fait leurs preuves avec des millions de documents et des milliers de reqûetes par jour.',

  'enterprise.searchExpertise.makeItEasy.id': 'features',
  'enterprise.searchExpertise.makeItEasy.alt': 'La complexité faite simplement',
  'enterprise.searchExpertise.makeItEasy.title': 'La complexité faite simplement',
  'enterprise.searchExpertise.makeItEasy.description': 'Votre moteur de recherche inclus des fonctionnalités comme le NLP et le NER. Wuha reduit la complexité de ces notions et vous permet de construire un moteur de recherche puissant avec une interface graphique.',

  'enterprise.searchExpertise.beAutonomous.id': 'experts',
  'enterprise.searchExpertise.beAutonomous.alt': 'Créé pour des experts de domaine',
  'enterprise.searchExpertise.beAutonomous.title': 'Créé pour des experts de domaine',
  'enterprise.searchExpertise.beAutonomous.description': 'Wuha a été créé pour les experts du domaine qui ont besoin d\'une recherche puissante. Nous accompagnons les chefs d\'entreprise à libérer la puissance de la recherche à leur industrie.',
}
