import React, { Component } from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'

import { localeData } from '../../i18n'

addLocaleData(localeData)

const getBrowserLanguage = () =>
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  'en'

const getLanguageWithoutRegionCode = (language) =>
  language.toLowerCase().split(/[_-]+/)[0]


export default ComposedComponent => {

  class withIntl extends Component {

    constructor(props) {
      super(props)

      const {
        locale,
      } = this.props

      this.state = {
        locale: locale || 'en',
      }
    }

    componentDidMount() {
      const {
        locale,
      } = this.state

      const updatedLocale = locale ||
        window.localStorage.getItem('lang') ||
        getBrowserLanguage(getLanguageWithoutRegionCode)

      this.setState({
        locale: updatedLocale,
      })
    }


    // Render
    render() {
      const {
        locale,
      } = this.state

      const messages = require(`../../i18n/locales/${locale}.js`) // eslint-disable-line

      return (
        <IntlProvider
          locale={locale}
          messages={messages}
        >
          <ComposedComponent {...this.props} />
        </IntlProvider>
      )
    }
  }

  return withIntl
}
