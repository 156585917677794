import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const linkHeaderClass = classNames({
  'footer-link-header': true,
})

class LinkHeader extends Component {

  render() {
    const {
      text,
    } = this.props

    return (
      <h3 className={linkHeaderClass}>
        {text}
      </h3>
    )
  }
}

LinkHeader.propTypes = {
  text: PropTypes.string.isRequired,
}

LinkHeader.defaultProps = {
  text: undefined,
}

export default LinkHeader
