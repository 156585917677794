import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Image from '../Image'
import RedLogo from '../../img/logos/wuha_red.svg'
import WhiteLogo from '../../img/logos/wuha_white.svg'

const SOURCES = {
  red: RedLogo,
  white: WhiteLogo,
}

const COLORS = ['red', 'white']

class Logo extends Component {
  render() {
    const { height, color } = this.props

    return (
      <Image
        src={SOURCES[color]}
        alt="logo"
        height={`${height}px`}
      />
    )
  }
}

Logo.propTypes = {
  height: PropTypes.any.isRequired,
  color: PropTypes.oneOf(COLORS),
}

Logo.defaultProps = {
  height: undefined,
  color: COLORS[0],
}

export default Logo
