import React, { Component } from "react"
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from "gatsby"

import injectContext from '../../components/injectContext'

import defaultMetaOGImage from '../../img/artworks/artwork_header_home.png'
import favicon16Icon from '../../img/favicons/favicon16.png'
import favicon32Icon from '../../img/favicons/favicon32.png'
import favicon48Icon from '../../img/favicons/favicon48.ico'

const NO_INDEX = process.env.GATSBY_NO_INDEX === 'true'

class SEO extends Component {

  render() {
    const {
      metaTitle,
      metaDescription,
      metaOGImage,
      metaOGType,
      languages,
      locale,
      slug,
    } = this.props

    return (
      <StaticQuery
        query={query}
        render={({
          site: {
            siteMetadata: {
              title,
              siteUrl,
              twitterUsername,
            },
          },
        }) => {

          // Generate alternative links
          const languagesMetaLinks = []
          if (languages) {
            languages.map(language => {
              const alternateUrl = `${siteUrl}/${language.value}${slug}`

              if (language.isDefault) {
                languagesMetaLinks.push(<link key={`x-default`} rel="alternate" hrefLang="x-default" href={alternateUrl} />)
              }

              if (language.value !== locale) {
                languagesMetaLinks.push(<link key={`og:locale:alternate-${language.value}`} rel="og:locale:alternate" content={language.value} />)
              }

              languagesMetaLinks.push(<link key={`alternate-${language.value}`} rel="alternate" hrefLang={language.value} href={alternateUrl} />)
            })
          }


          return (
            <Helmet>
              {/* Indexing */}
              {NO_INDEX && <meta name="robots" content="noindex" />}

              {/* Site Verification (GSC) */}
              <meta name="google-site-verification" content="Ht5Ww6mNaqQG01guLAOOUKuo9Js97nHmnxYXkaClyec" />

              {/* General */}
              <title>{metaTitle}</title>
              <meta name="description" content={metaDescription} />
              <link rel="icon" type="image/png" sizes="16x16" href={`${favicon16Icon}`} />
              <link rel="icon" type="image/png" sizes="32x32" href={`${favicon32Icon}`} />
              <link rel="shortcut icon" type="image/png" sizes="48x48" href={`${favicon48Icon}`} />

              {/* OpenGraph */}
              <meta property="og:site_name" content={title} />
              <meta property="og:title" content={metaTitle} />
              <meta property="og:description" content={metaDescription} />
              <meta property="og:type" content={metaOGType} />
              <meta property="og:image" content={`${siteUrl}${metaOGImage}`} />
              <meta property="og:image:alt" content={metaTitle} />
              <meta property="og:locale" content={locale} />
              <meta property="og:url" content={`${siteUrl}/${locale}${slug}`} />
              {languagesMetaLinks.map(metaLink => metaLink)}

              {/* Twitter */}
              <meta name="twitter:title" content={metaTitle} />
              <meta name="twitter:description" content={metaDescription} />
              <meta name="twitter:image" content={`${siteUrl}${metaOGImage}`} />
              <meta name="twitter:site" content={twitterUsername} />
              <meta name="twitter:creator" content={twitterUsername} />
            </Helmet>
          )
        }
        }
      />
    )
  }
}

SEO.propTypes = {
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  metaOGImage: PropTypes.string,
  metaOGType: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}

SEO.defaultProps = {
  metaTitle: undefined,
  metaDescription: undefined,
  metaOGImage: defaultMetaOGImage,
  metaOGType: 'website',
  languages: undefined,
  locale: undefined,
  slug: undefined,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        siteUrl
        twitterUsername
      }
    }
  }
`

export default injectContext(SEO)
