import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import injectContext from '../injectContext'

const I18nLink = ({ to, children, locale, ...rest }) => {
  const toWithLang = locale ? `/${locale}${to}` : `${to}`

  return (
    <Link to={toWithLang} {...rest}>
      {children}
    </Link>
  )
}

I18nLink.propTypes = {
  to: PropTypes.string.isRequired,
  locale: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
}

export default injectContext(I18nLink)
